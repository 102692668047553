
@import '../../../variables';



.workshop-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 13vw;
    margin-top: 1%;

    .workshop-back-button { 
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        width: 20%;
        height: 60%;
        cursor: pointer;
        z-index: 15;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        & img {
            height: 90%;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .workshop-title { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 100%;
        color: $white-100;
        font-size: 5vw;
        font-weight: 700;
    }

    .workshop-info {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 20%;
        height: 100%;
        
        & button {
            height: 50%;
            aspect-ratio: 1/1;
            border: none;
            background-color: #E79A28;
            border-radius: 1vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                }
            }
        }  
    }
}

.workshop-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow-y: auto;

    .workshop-table-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2%;
        width: 90vw;
        min-height: 50.5vw;
    
        & img {
            width: 100%;
            border-radius: 3vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }
    
        .workshop-table-overlay {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 50.7vw;
            border-radius: 3vw;
            background-color: rgba(0, 0, 0, 0.40);
    
            .recipe-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 75%;
                height: 75%;
    
                .recipe-create-component {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 29%;
                    aspect-ratio: 1/1;
                    border-radius: 2vw;
                    margin-top: 7%;
    
                    &.valid {
                        background-color: rgba(200, 255, 0, 0.7);
                        border: solid 3px rgb(200, 255, 0);
                    }
                
                    &.invalid {
                        background-color: rgba(255, 67, 67, 0.7);
                        border: solid 3px rgb(255, 26, 26);
                    }
    
                    & img {
                        border-radius: 0;
                        width: 100%;
                        box-shadow: none;
                    }
    
                    .recipe-create-amount {
                        position: absolute;
                        display: flex;
                        justify-content: right;
                        width: 13%;
                        font-size: 4vw;
                        font-weight: 700;
                        color: white;
                        margin-top: 7%;
                        margin-right: 2%;
                    }
                }
    
                .recipe-elements {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;
                    height: 40%;
                    margin-top: 8%;
    
                    .recipe-element {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 75%;
                        aspect-ratio: 1/1;
                        border-radius: 2vw;
    
                        & img {
                            border-radius: 0;
                            width: 70%;
                            box-shadow: none;
                        }
    
                        & span {
                            height: 25%;
                            font-size: 2.7vw;
                            font-weight: 500;
                            color: white;
                        }
    
                        &.valid {
                            background-color: rgba(200, 255, 0, 0.7);
                            border: solid 3px rgb(200, 255, 0);
                        }
                    
                        &.invalid {
                            background-color: rgba(255, 67, 67, 0.7);
                            border: solid 3px rgb(255, 26, 26);
                        }
                    }
                }
    
                .recipe-lines {
                    position: absolute;
                }
            }

            .recipe-bottom-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 25%;

                .recipe-amount-box {       
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;
                    height: 100%;
        
                    .recipe-amount-minus {
                        position: relative;
                        height: 55%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }

                        &.disabled {
                            pointer-events: none;
                            //opacity: 0.8;
                        }
                    }
        
                    .recipe-amount {
                        position: relative;
                        width: 45%;
                        height: 55%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $lightest-color-100;
                        border: none;
                        border-radius: 1.5vw;
                        color: $dark-color-100;
                        font-weight: 700;
                        font-size: 3.8vw;
                        text-align: center;
                        margin-left: 4%;
                        margin-right: 4%;
                    }
        
                    .recipe-amount-plus {
                        position: relative;
                        height: 55%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }

                        &.disabled {
                            pointer-events: none;
                            //opacity: 0.8;
                        }
                    }
                }

                .workshop-create-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 33%;
                    height: 65%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    cursor: pointer;
                    background-color: $blue-color-100;
                    border: none;
                    padding: 0;
                    border-radius: 2vw;
                    color: white;
                    font-size: 2.7vw;
                    font-weight: 500;
                
                    &:active {
                        transform: scale(0.95);
                    }
                    & img {
                        box-shadow: none;
                        width: 30%;
                    }
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }
                    &.disabled {
                        pointer-events: none;
                        opacity: 0.8;
                    }
                    & span {
                        margin-left: 4%;
                    }

                    .loading-animation-create { 
                        @include load-animation;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        background: none;
                        margin-top: 7%;
                    }
                }
            }
        }
    
        // Фейерверк
        $particles: 60;
        $width: 500;
        $height: 400;
        $box-shadow: ();
        $box-shadow2: ();
        @for $i from 0 through $particles {
        $box-shadow: $box-shadow,
                    random($width)-$width / 2 + px
                    random($height)-$height / 1.2 + px
                    hsl(random(360), 100, 50);
        $box-shadow2: $box-shadow2, 0 0 #fff
        }
        @mixin keyframes ($animationName) {
            @-webkit-keyframes #{$animationName} {
                @content;
            }
    
            @-moz-keyframes #{$animationName} {
                @content;
            }
    
            @-o-keyframes #{$animationName} {
                @content;
            }
    
            @-ms-keyframes #{$animationName} {
                @content;
            }
    
            @keyframes #{$animationName} {
                @content;
            }
        }
    
        @mixin animation-delay ($settings) {
            -moz-animation-delay: $settings;
            -webkit-animation-delay: $settings;
            -o-animation-delay: $settings;
            -ms-animation-delay: $settings;
            animation-delay: $settings;
        }
    
        @mixin animation-duration ($settings) {
            -moz-animation-duration: $settings;
            -webkit-animation-duration: $settings;
            -o-animation-duration: $settings;
            -ms-animation-duration: $settings;
            animation-duration: $settings;
        }
    
        @mixin animation ($settings) {
            -moz-animation: $settings;
            -webkit-animation: $settings;
            -o-animation: $settings;
            -ms-animation: $settings;
            animation: $settings;
        }
    
        @mixin transform ($settings) {
            transform: $settings;
            -moz-transform: $settings;
            -webkit-transform: $settings;
            -o-transform: $settings;
            -ms-transform: $settings;
        }
    
        .pyro > .before, .pyro > .after {
            left: 10%;
            z-index: 100;
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            box-shadow: $box-shadow2;
            @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
        }
                
        .pyro > .after {
            @include animation-delay((1.25s, 1.25s, 1.25s));
            @include animation-duration((1.25s, 1.25s, 6.25s));
        }
                    
        @include keyframes(bang) {
            to {
                box-shadow:$box-shadow;
            }
        }
                
            @include keyframes(gravity)  {
            to {
                @include transform(translateY(200px));
                opacity: 0;
            }
        }
                
        @include keyframes(position) {
            0%, 19.9% {
                margin-top: 10%;
                margin-left: 40%;
            }
            20%, 39.9% {
                margin-top: 40%;
                margin-left: 30%;
            }
            40%, 59.9% {  
                margin-top: 20%;
                margin-left: 70%
            }
            60%, 79.9% {  
                margin-top: 30%;
                margin-left: 20%;
            }
            80%, 99.9% {  
                margin-top: 30%;
                margin-left: 80%;
            }
        }
    }
    
    .workshop-components-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        width: 90vw;
        min-height: 112vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        background-color: $light-color-100;
        border-radius: 3vw;
        margin-bottom: 5%;
    
        .workshop-components-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 8%;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
        }
    
        .workshop-components {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            padding-left: 5%;
            width: 100%;
            min-height: 90%;
            color: $dark-color-100;
            margin-bottom: 3%;
    
            .workshop-component {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 15%;
                aspect-ratio: 1/1.2;
                margin: 2% 2%;
                background-color: white;
                border: solid $dark-color-100 2px;
                border-radius: 1.5vw;
                padding: 0;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                .image {
                    width: 100%;
                    height: 70%;

                    & img {
                        height: 100%;
                    }
                }
                
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
    
                .span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 30%;
                    color: rgb(255, 255, 255);
                    font-weight: 500;
                    font-size: 3.5vw;
                    background-color: $blue-color-100;
                    border-radius: 0 0 0.8vw 0.8vw;
                    border-top: solid $dark-color-100 2px;

                    &.full {
                        color: rgb(255, 25, 25);
                        font-weight: 700;
                    }
                }
    
                &.selected {
                    background-color: $dark-color-100;

                    .span {
                        background-color: $blue-color-90;
                    }
                }
            }
        }
    }
}

