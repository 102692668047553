
@import '../../../variables';

.info-rig-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 96vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 30%;
            margin-top: 9%;

            &.speed {
                margin-top: 2%;
            }

            .lvl-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                border: none;
                background: none;
                cursor: pointer;

                & img {
                    width: 125%;
                }

                &:active {
                    & img {
                        width: 115%;
                    }
                }
            }

            .param-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 90%;
                background-color: $light-color-100;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .title-param {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 35%;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                    
                    .lvl-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 85%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        color: white;
                        font-size: 2.3vw;
                        margin-left: 2%;
                        border-radius: 1.5vw;
                    }
                }

                .param {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 60%;

                    .img-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 70%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        border-radius: 2vw;

                        & img {
                            height: 75%;
                        }
                    }  

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50%;
                        width: 27%;
                        background-color: white;
                        border-radius: 0 2vw 2vw 0;
                        color: $dark-color-100;
                        font-size: 3.5vw;
                    }
                }
            }

            .lvl-right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                border: none;
                background: none;
                cursor: pointer;

                & img {
                    width: 125%;
                }

                &:active {
                    & img {
                        width: 115%;
                    }
                }
            }
        }


        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90%;
            height: 32%;
            font-size: 3.5vw;
            color: $dark-color-100;
            font-weight: 500;

            & span {
                margin-bottom: 4%;
            }

            .text2 {
                margin-bottom: 2%;
                font-size: 3vw;
            }
        }
    }
}
