
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');

@import 'variables';

img {
    pointer-events: none;
}

button, 
button:focus, 
button:active {
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: Montserrat Alternates, sans-serif;
}

html, body {
    font-size: 10px;
    font-family: Montserrat Alternates, sans-serif;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background-color: $dark-color-100; 
    margin: 0;
    padding: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .tech-works {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: $dark-color-100;
        margin-bottom: 25%;

        & img {
            width: 70%;
        }

        .tech-works-title {
            color: white;
            font-size: 10vw;
            margin-bottom: 10%;
        }

        & form {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80vw;
            height: 10vw;

            & input {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 10vw;
                border-radius: 2vw;
                border: solid 2px $orange;
                cursor: pointer;
            }
    
            & button {
                margin-left: 5%;
                width: 25%;
                height: 75%;
                cursor: pointer;
                padding: 0;
                border: none;
                border-radius: 1.5vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.5vw;
            }
        }
    }
}

::-webkit-scrollbar {
    display: none;
}

  


