
@import '../../variables';

.mini-game-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: $dark-color-100;
    z-index: 14;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;

    .mini-game-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content:  center;
        height: 7%;
        width: 100%;

        .mini-game-back {
            position: absolute;
            display: flex;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            align-items: center;
            justify-content: center;
            width: 14vw;
            height: 70%;
            left: 5vw;
            cursor: pointer;
            z-index: 15;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 90%;
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }

        .mini-game-title {
            position: relative;
            display: flex;
            color: $white-100;
            font-size: 4.5vw;
            font-weight: 700;
            white-space: nowrap;
        }

        .mini-game-info {
            position: absolute;
            display: flex;
            background-color: $orange;
            border-radius: 1.4vw;
            border: none;
            align-items: center;
            justify-content: center;
            width: 8vw;
            height: 70%;
            right: 5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            z-index: 15;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-orange;
                }
            }
        }
    }

    .mini-game-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86%;
        height: 70%;
        padding: 2%;
        background-color: $blue-color-100;
        border-radius: 3vw;
        margin-top: 2%;

        .mini-game-multiplies {
            position: relative;
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(11, 1fr);
            align-content: end;
            height: 100%;
            width: 12%;

            .mini-game-multiplier {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $dark-color-100;
                font-size: 2.5vw;
                background-color: $light-color-100;
                border-radius: 1vw;
                width: 90%;
                height: 40%;
            }
        }

        .mini-game-grid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(11, 1fr);
            width: 88%;
            height: 100%;
            align-content: end;
            align-items: center;
            justify-content: right;
            
            .mini-game-cell {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-color-100;
                width: 90%;
                height: 90%;
                border: none;
                border-radius: 1.5vw;
                cursor: pointer;
                color: $dark-color-100;
                font-size: 5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                overflow: hidden;
    
                & img {
                    width: 70%;
                }

                &.active {
                    background-color: rgb(74, 148, 233);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-light;
                    }
                }
            }
        }
    }

    .mini-game-result {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 17%;

        .mini-game-prize {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 70%;
            background-color: $blue-color-100;
            border-radius: 2vw;

            .mini-game-prize-title {
                color: white;
                font-size: 3.5vw;
                font-weight: 700;
            }

            .mini-game-prize-resources {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 65%;

                .mini-game-prize-money {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;

                    .mini-game-prize-money-img {
                        height: 85%;
                        aspect-ratio: 1/1;
                        background-color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 1.5vw;

                        & img {
                            height: 80%;
                        }
                    }

                    .mini-game-prize-money-amount {
                        width: 55%;
                        height: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 1.5vw 1.5vw 0;
                        background-color: $light-color-100;
                        color: $dark-color-100;
                        font-size: 2.5vw;
                        font-weight: 700;
                    }
                }

                .mini-game-prize-fuel {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;
                    margin-left: 3%;

                    .mini-game-prize-fuel-img {
                        height: 85%;
                        aspect-ratio: 1/1;
                        background-color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 1.5vw;

                        & img {
                            height: 80%;
                        }
                    }
                    .mini-game-prize-fuel-amount {
                        width: 55%;
                        height: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 1.5vw 1.5vw 0;
                        background-color: $light-color-100;
                        color: $dark-color-100;
                        font-size: 2.5vw;
                        font-weight: 700;
                    }
                }
            }

        }

        @keyframes borderAnimation {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 100% 0;
            }
        }

        .mini-game-prize-button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            height: 35%;
            background-color: white;
            border-radius: 2vw;
            cursor: pointer;
            border: none;
            margin-left: 10%;
            color: $dark-color-100;
            font-size: 3.5vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                right: -3px;
                bottom: -3px;
                border-radius: 2.2vw;
                background: linear-gradient(90deg, #ff0000, #00ff00, #0000ff, #ff0000);
                background-size: 300% 100%;
                z-index: -1;
                animation: borderAnimation 4s linear infinite;
                opacity: 0;
            }
        
            &:not(:disabled)::before {
                opacity: 1;
            }

            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }

            &.disabled {
                opacity: 0.7;
                pointer-events: none;
            }

            .loading-animation{ 
                @include load-animation;
                margin: 0;
                padding: 0;
                height: 90%;
                width: 100%;
                background: none;
                margin-top: 5%;
            }
        }

    }
}

.mg-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 30;

    .mg-content {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 45vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 30%;
        align-items: center; 

        .mg-modal-title {
            position: relative;
            display: flex;
            color: $dark-color-100;
            font-size: 6vw;
            font-weight: 700;
            margin-top: 2%;
        }

        .mg-message {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 60%;

            .mg-message1 {
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-top: 1%;
            }

            .mg-message2 {
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-top: 1%;
            }

            .mg-res-box {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90%;
                height: 40%;
                margin-top: 2%;

                .mg-res-money {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;

                    .mg-res-money-img {
                        height: 100%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 1.5vw;

                        & img {
                            height: 80%;
                        }
                    }

                    .mg-res-money-amount {
                        width: 50%;
                        height: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 1.5vw 1.5vw 0;
                        background-color: $light-color-100;
                        color: $dark-color-100;
                        font-size: 3vw;
                        font-weight: 700;
                    }
                }

                .mg-res-fuel {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;

                    .mg-res-fuel-img {
                        height: 100%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 1.5vw;

                        & img {
                            height: 80%;
                        }
                    }
                    .mg-res-fuel-amount {
                        width: 50%;
                        height: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 1.5vw 1.5vw 0;
                        background-color: $light-color-100;
                        color: $dark-color-100;
                        font-size: 3vw;
                        font-weight: 700;
                    }
                }

                .mg-trap-img {
                    height: 130%;
                    aspect-ratio: 1/1;
                    border-radius: 1.5vw;
                    margin-top: 3%;

                    & img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }
                }
            }
        }

        .mg-ok {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 15%;
            border: none;
            border-radius: 1.5vw;
            cursor: pointer;
            background-color: $blue-color-100;
            color: white;
            font-size: 4vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
        }
    }
}

.mg-info-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 30;

    .mg-info-content {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 55%;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center; 

        .mg-info-ok {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 8%;
            border: none;
            border-radius: 1.5vw;
            cursor: pointer;
            background-color: $blue-color-100;
            color: white;
            font-size: 4vw;
            font-weight: 700;
            margin-top: 2.5%;
            
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
        }

        & img {
            width: 80%;
            margin-top: 5%;
            border-radius: 1.5vw;
        }
    }
}