
@import '../../../variables';

.modal-rented-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .modal-rented {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 30%;
        align-items: center; 
        
        .modal-rented-title {
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
            margin-top: 3%;
        }

        .modal-rented-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: $dark-color-100;
            font-size: 2.5vw;
            font-weight: 500;
            margin-top: 2%;
            width: 90%;
        }

        .modal-rented-ships {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 90%;
            height: 46%;
            background-color: $light-color-100;
            border-radius: 2vw;
            margin-top: 2%;
            overflow-y: auto;
            padding-bottom: 2%;

            .modal-rented-ship {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                height: 30%;
                background-color: $blue-color-100;
                border-radius: 2vw;
                margin-top: 2.5%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                color: white;
                font-size: 4vw;
                font-weight: 700;
                padding: 2%;
            }
        }

        .modal-rented-ok {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-color-100;
            width: 25%;
            height: 13%;
            border: none;
            border-radius: 2vw;
            cursor: pointer;
            margin-top: 2.5%;
            color: white;
            font-size: 4vw;
            font-weight: 900;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
        }

    }
}