
@import '../../../variables';


.ships-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 12vw;
    
    .ships-back-button { 
        position: relative;
        display: flex;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        color: $dark-color-100;
        font-size: 3vw;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 60%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
        & img {
            height: 90%;
        }
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .title-ships { 
        color: white;
        font-size: 5vw;
        font-weight: 700;
    }
    
    .buy-ships-button {
        position: relative;
        display: flex;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        color: $dark-color-100;
        font-size: 3vw;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 60%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }
}

.ship-loading-animation{ 
    position: relative;
    display: flex;
    flex-direction: column;
    height: 84%;
    width: 90vw;
    margin-top: 2%;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: $light-color-90; 
    border-radius: 3vw;
    margin-bottom: 3.5%;
    padding-top: 5%;

    @include load-animation;
}

.ships-box { 
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    border: none;
    overflow-y: auto;
    margin-top: 1%;

    .ship-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 35vw;
        border-radius: 2vw;
        background-color: $light-color-100;
        margin-bottom: 5%;

        .ship-img-box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 40%;
            
            & img {
                height: 84%;
                border-radius: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .ship-name {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 2.5vw;
                font-weight: 500;
                width: 48%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-right: 28%;
                margin-bottom: 60%;
            }

            .ship-lvl {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 2.3vw;
                font-weight: 500;
                width: 25%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 52%;
                margin-bottom: 60%;
            }

            .ship-capacity {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 3vw;
                font-weight: 500;
                width: 30%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 47%;
                margin-top: 60%;

                & img {
                    border-radius: 0;
                    box-shadow: none;
                    height: 85%;
                    margin-left: 5%;
                }
            }

            .ship-rented {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 2.5vw;
                font-weight: 700;
                height: 16%;
                aspect-ratio: 1.1/1;
                align-items: center;
                justify-content: center;
                margin-right: 57%;
                margin-top: 60%;

                & img {
                    border-radius: 0;
                    box-shadow: none;
                    height: 85%;
                    margin-left: 5%;
                }
            }

            .animate-success {
                animation: shadow-drop-center 0.7s linear both;
            }
        
            @keyframes shadow-drop-center {
                0% {
                    box-shadow: 0 0 0 0 transparent;
                }
                100% {
                    box-shadow: 0 0 10vw 0 rgba(255, 230, 0, 0.95);
                }
            }

        }

        .right-ship {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 62%;

            .technologies-box {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width : 48%;
                height: 35%;
                background-color: white;
                border-radius: 2vw;

                .technologie {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 80%;
                    aspect-ratio: 1/1;
                    background-color: $blue-color-100;
                    border-radius: 1.5vw;
                    border: none;
                    padding: 0;
                    cursor: pointer;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }

                    &:active {
                        transform: scale(0.95);
                    }

                    & img {
                        opacity: 0.3;
                        height: 70%;
                    }
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                width : 90%;
                height: 25%;
                margin-top: 10%;

                .upgrade-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;
                    background-color: $blue-color-100;
                    border: none;
                    margin: 0 3%;
                    cursor: pointer;
                    border-radius: 1.5vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    color: white;
                    font-size: 2.6vw;
                    line-height: 1.2;

                    & img {
                        height: 75%;
                    }

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.7;
                    }
                }

                .rent-out-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 45%;
                    background-color: $blue-color-100;
                    border: none;
                    margin: 0 3%;
                    cursor: pointer;
                    border-radius: 1.5vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    color: white;
                    font-size: 2.6vw;
                    line-height: 1.2;

                    & img {
                        height: 75%;
                    }

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.7;
                    }
                }
            }
    
            // Ожидает аренды
            .ship-rent-price {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba(28, 28, 28, 0.87);
                border: none;
                border-radius: 0 1.75vw 1.75vw 0;

                .ship-rent-price-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: none;
                    border-radius: 2.5vw;
                    color: $dark-color-100;
                    font-size: 3.2vw;
                    font-weight: 700;
                    width: 75%;
                    height: 60%;
                    background-color: $light-color-100;
                    padding-top: 3%;

                    .ship-rent-price-text2 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 20%;
                        margin-top: 3%;
                        color: $dark-color-100;
                        font-size: 3.2vw;
                        font-weight: 700;

                        & img {
                            height: 100%;
                            margin-right: 2%;
                        }
                    }

                    .ship-rent-cancel-button {
                        display: flex;
                        width: 60%;
                        height: 35%;
                        padding: 1.5% 0 1.5% 0; 
                        border-radius: 1.5vw;
                        border: none;
                        background-color: $blue-color-100;
                        cursor: pointer;
                        outline: none;
                        color: white;
                        font-family: Montserrat Alternates;
                        font-size: 2.6vw;
                        font-weight: 900;
                        align-items: center;
                        justify-content: center;
                        margin-top: 4%;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    
                        &:active {
                            transform: scale(0.95);
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $hover-blue;
                            }
                        }

                        &.disabled {
                            pointer-events: none;
                            opacity: 0.6;
                        }

                        .loading-cancel-rent {
                            @include load-animation;
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            // В рейсе
            .ship-travel-info {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba(28, 28, 28, 0.87);
                border: none;
                border-radius: 0 1.75vw 1.75vw 0;

                .ship-travel-info-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    border-radius: 2.5vw;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                    font-weight: 500;
                    width: 55%;
                    height: 50%;
                    background-color: $light-color-100;
                    line-height: 1.7;
                }

                .info-button {
                    position: absolute;
                    height: 7vw;
                    width: 7vw;
                    top: 6%;
                    right: 4%;
                    border: none;
                    background-color: #E79A28;
                    border-radius: 1vw;
                    color: $dark-color-100;
                    font-size: 5vw;
                    font-weight: 700;
                    cursor: pointer;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: #af741a;
                        }
                    }
                } 
            }
        }

        // Ты арендуешь
        .is-rented-info {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 62%;

            .is-rented-voyages-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 75%;
                height: 27%;
                border-radius: 2vw;
                background-color: white;
                color: $dark-color-100;
                font-size: 2.7vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .is-rented-timer {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 75%;
                height: 45%;
                margin-top: 5%;
                border-radius: 2vw;
                background-color: white;
                color: $dark-color-100;
                font-size: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                & span {
                    margin-top: 6%;
                }
            }
        }
    }

    .ships-not {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        color: $dark-color-100;
        font-size: 5vw;
        font-weight: 500;

        & span {
            margin-bottom: 3%;
        }
    }
    
}

.buy-ships-button {
    position: absolute;
    display: flex;
    background-color: $white-100;
    border-radius: 1.4vw;
    border: none;
    color: $dark-color-100;
    font-size: 3vw;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    width: 18vw;
    height: 7vw;
    right: 0vw;
    top: 3%;
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

    &:active {
        transform: scale(0.95);
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $hover-light;
        }
    }
}

