
@import '../../variables';

.soon-box {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16vw;
    height: 4%;
    border-radius: 1.5vw;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    transition: opacity 0.3s ease-in-out;

    &.fade-in {
        opacity: 1;
    }
    
    &.fade-out {
        opacity: 0;
    }

    & img {
        width: 12%;
        margin: 0 3%;
    }

    & span {
        color: white;
        font-size: 3vw;
        font-weight: 700;
    }

    &.ship {
        transform: translate(15%, 30%);
    }
    &.header {
        transform: translate(20%, 40%);
    }
    &.rig-soon {
        transform: translate(-115%, 40%);
    }
}