@import '../../../variables';

.event-quest-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 106;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        //height: 120vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 10%;
        align-items: center;
        padding-bottom: 5%;

        .event-image {
            width: 90%;
            margin-top: 5%;
            border-radius: 2.5vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .event-title {
            text-align: center;
            width: 90%;
            color: $dark-color-100;
            font-size: 4vw;
            margin-top: 2%;
        }
        
        .event-question {
            text-align: justify;
            width: 90%;
            color: $dark-color-100;
            font-size: 3.5vw;
            margin-top: 4%;
            margin-bottom: 1%;
        }

        .event-answer {
            display: flex;
            align-items: center;
            text-align: justify;
            width: 90%;
            margin-top: 3%;

            & button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10%;
                aspect-ratio: 1/1;
                cursor: pointer;
                padding: 0;
                background-color: $light-color-100;
                border: solid 2px $dark-color-100;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-light;
                    }
                }
                & img {
                    width: 80%;
                }
            }

            & span {
                width: 88%;
                margin-left: 2%;
                color: $dark-color-100;
                font-size: 3vw;
                cursor: pointer;
                transition: color 0.5s ease;
                user-select: none;

                &.correct {
                    color: rgb(108, 189, 32);
                    font-weight: 700;
                }
            
                &.incorrect {
                    color: red;
                    font-weight: 700;
                }
            }

            .point-animation {
                position: absolute;
                color: $dark-color-100;
                margin-left: 10%;
                font-size: 4vw;
                opacity: 1;
                animation: pointMoveUp 1.5s ease-in-out forwards;
                &.hide {
                    display: none;
                }
            }
            
            @keyframes pointMoveUp {
                0% {
                    opacity: 1;
                }
                100% {
                    margin-bottom: 60%;
                    opacity: 0;
                }
            }            
            
        }
        
        .event-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5%;
            width: 30%;
            height: 9vw;
            padding: 0;
            border: none;
            cursor: pointer;
            background-color: $blue-color-100;
            color: white;
            font-size: 4vw;
            border-radius: 2vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &:active {
                transform: scale(0.95);
            }
            &.loading {
                pointer-events: none;
                opacity: 0.7;
            }
            .loading-animation { 
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%; 
                height: 90%; 
        
                @include load-animation;
            }
        }
    }
}