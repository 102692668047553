@import '../../../../variables';

.dutchman-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        min-height: 40vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;
        padding-bottom: 5%;
        overflow-y: auto;

        .box-title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 3.7vw;
            font-weight: 700;
        }

        .loading-content { 
            @include load-animation;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            background: none;
            margin-top: 50%;
            margin-left: 75%;
        }

        .event-message {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-wrap: wrap;
            margin-top: 24%;
            color: $dark-color-100;
            font-size: 4.5vw;
        }
    }
}