
@import '../../variables';

.market-sell {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;

    .market-sell-store {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin-top: 4%;
    
        .sell-switch-box {
            display: flex;
            align-items: end;
            width: 100%;
            height: 8.5vw;
    
            .switch-button1 {
                width: 28%;
                height: 100%;
                border: none;
                padding: 0;
                border-radius: 2vw 0 0 0;
                cursor: pointer;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                border-right: solid 2px $dark-color-100;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.active {
                    box-shadow: 
                        0px 8px 8px rgba(0, 0, 0, 0.30) inset,
                        0px 8px 8px rgba(0, 0, 0, 0.30);
                    background-color: $dark-color-100;
                    border: none;
                }
            }
    
            .switch-button2 {
                width: 36%;
                height: 100%;
                border: none;
                padding: 0;
                border-radius: 0;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                cursor: pointer;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.active {
                    box-shadow: 
                        0px 8px 8px rgba(0, 0, 0, 0.30) inset,
                        0px 8px 8px rgba(0, 0, 0, 0.30);
                    background-color: $dark-color-100;
                }
            }

            .switch-button3 {
                width: 36%;
                height: 100%;
                border: none;
                padding: 0;
                border-radius: 0 2vw 0 0;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                border-left: solid 2px $dark-color-100;
                cursor: pointer;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.active {
                    box-shadow: 
                        0px 8px 8px rgba(0, 0, 0, 0.30) inset,
                        0px 8px 8px rgba(0, 0, 0, 0.30);
                    background-color: $dark-color-100;
                    border: none;
                }
            }
        }
    
        .store {
            display: flex;
            align-content: flex-start;
            justify-content: start;
            flex-wrap: wrap;
            width: 99%;
            background-color: $light-color-100;
            border-radius: 0 0 2vw 2vw;
            padding-top: 1%;
            padding-bottom: 1%;
            padding-left: 1%;
    
            .element-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
                background-color: white;
                border: solid $dark-color-100 2px;
                height: 17vw;
                aspect-ratio: 1/1.2;
                cursor: pointer;
                margin: 2%;
                border-radius: 1.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                & img {
                    height: 75%;
                }

                & span {
                    width: 100%;
                    height: 25%;
                    color: white;
                    background-color: $blue-color-100;
                    border-radius: 0 0 0.8vw 0.8vw;
                    box-sizing: content-box;
                    font-size: 3vw;
                    border-top: solid $dark-color-100 2px;
                    box-sizing: border-box;
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgb(223, 223, 223);
                    }
                }
            }
            
            .loading-animation-sell-list { 
                @include load-animation;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 40vw;
                margin-top: 0%;
                margin-left: 38%;
                margin-top: 20%;
                background: none;
            }
        }
    }

    .my-lots {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        //min-height: 40vw;
        margin-top: 5%;
        border-radius: 2vw;
        background-color: $light-color-100;
        margin-bottom: 5%;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 10vw;
            color: $dark-color-100;
            font-size: 5vw;
        }

        .my-lots-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 7vw;
            max-height: auto;
            margin-top: 2vw;

            .my-lot-item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90%;
                height: 12vw;
                margin-bottom: 8vw;

                .img-box {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white-100;
                    width: 19%;
                    aspect-ratio: 1/1;
                    border-radius: 2vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                    & img {
                        height: 95%;
                    }
    
                    .value-box {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        width: 65%;
                        height: 60%;
                        margin-right: 35%;
                        margin-top: 40%;
                        background-color: rgba(17, 45, 78, 0.7);
                        border-radius: 0 100% 0 2vw;
                        color: white;
                        font-size: 3.5vw;
                        font-weight: 500;
        
                        & span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 60%;
                            margin-left: 13%;
                            margin-top: 10%;
                        }
                    }
                }

                .info-box {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    background-color: $blue-color-100;
                    border-radius: 0 2vw 2vw 0;
                    height: 95%;
                    width: 82%;
                    margin-top: 0;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    .price-box {
                        display: flex;
                        flex-direction: column;
                        width: 32%;
                        height: 75%;
                        margin-left: 2%;
                        background-color: $light-color-100;
                        border: none;
                        border-radius: 1.5vw;
                        align-items: center;
                        color: $dark-color-100;
    
                        .title {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 50%; 
                            font-size: 2.8vw;
                            font-weight: 500;
                        }
        
                        .price {
                            display: flex;
                            margin-top: 2%;
                            
                            justify-content: center;
                            width: 100%;
                            height: 50%;
                            font-size: 2.6vw;
                            font-weight: 500;
    
                            & img {
                                height: 85%;
                                margin-left: 3%;
                            }
                        }
                    }
                }

                .cancel-button {
                    border: none;
                    background-color: white;
                    border-radius: 1.5vw;
                    height: 60%;
                    width: 35%;
                    margin-left: 28%;
                    font-size: 3vw;
                    color: $dark-color-100;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    cursor: pointer;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-light;
                        }
                    }
                    &:active {
                        transform: scale(0.95);
                    }

                    .loading-animation-cancel { 
                        @include load-animation;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        margin-top: 4%;
                        background: none;
                    }
                }
            }
        }

        
    }
}