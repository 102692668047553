
@import '../../variables';

@mixin map-marker {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $light-color-100;
    width: 21%;
    aspect-ratio: 1/1;
    border: solid 3px $dark-color-100;
    border-radius: 9999vw;
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            border-color: $hover-dark;
            .marker-name {
                background-color: $hover-dark;
            } 
        }
    }

    &:active {
        transform: scale(0.95);
    }

    .image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 9999vw;
        overflow: hidden;

        & img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }

    .map-soon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 102%;
        width: 102%;
        border-radius: 9999vw;
        background-color: rgba(0, 0, 0, 0.50);

        & img {
            height: 55%;
            margin-bottom: 15%;
        }
    }

    .marker-name {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-wrap: wrap;
        line-height: 1;
        width: 130%;
        height: 34%;
        background-color: $dark-color-100;
        border-radius: 1.5vw;
        color: white;
        overflow: hidden;
        font-size: 3vw;
        font-weight: 700;
        margin-top: 95%;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    }
}

.map-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    opacity: 1;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    will-change: transform;
    overflow-x: hidden;
    overflow-y: hidden;

    &.fade-out {
        opacity: 0;
        transform: scale(0.95);
    }

    &.fade-in {
        opacity: 1;
        transform: scale(1);
    }

    .map-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        opacity: 1;
        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
        will-change: transform;
        overflow-x: hidden;
        overflow-y: auto;
    
        .map {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%; 
            height: auto;
            margin-top: 3%;
            margin-bottom: 3%;
        
            .map-image {
                width: 90%;
                height: 100%;
                border-radius: 4vw;
            }
        
            #map-marker-port {
                @include map-marker;
                left: 55%;
                top: 75%;
            }
            #map-marker-autodrome {
                @include map-marker;
                left: 17%;
                top: 55%;
            }
            #map-marker-airport {
                @include map-marker;
                left: 52%;
                top: 33%;
            }
            #map-marker-cosmodrome {
                @include map-marker;
                left: 25%;
                top: 6%;
            }
        }
    }
}