
@import '../../../variables';

.rent-out-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;

    .modal-rent-out {
        position: relative;
        display: flex;
        justify-content: center;
        border: none;
        border-radius: 3vw;
        background-color: white;
        min-width: 80vw;
        height: 50vw;
    
        .modal-rent-text {
            position: absolute;
            display: flex;
            color: $dark-color-100;
            width: 75%;
            text-align: center;
            font-size: 3.5vw;
            font-weight: 700;
            text-wrap: wrap;
            margin-top: 2%;
        }

        .modal-rent-info {
            position: absolute;
            display: flex;
            color: $dark-color-100;
            width: 46%;
            text-align: center;
            font-size: 2vw;
            font-weight: 700;
            text-wrap: wrap;
            margin-top: 22%;
            margin-left: 50%;
        }

        .modal-ship-img-box {
            position: absolute;
            display: flex;
            height: 65%;
            width: auto;
            margin-left: -45%;
            margin-top: 17%;
            
            & img {
                border-radius: 2vw;
            }

            .modal-ship-name {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1vw;
                color: $dark-color-100;
                font-size: 2.3vw;
                font-weight: 700;
                width: 50%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 4%;
                margin-top: 3%;
            }

            .modal-ship-lvl {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1vw;
                color: $dark-color-100;
                font-size: 2.3vw;
                font-weight: 700;
                width: 25%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 72%;
                margin-top: 3%;
            }

            .modal-ship-capacity {
                position: absolute;
                display: flex;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1vw;
                color: $dark-color-100;
                font-size: 3vw;
                font-weight: 700;
                width: 30%;
                height: 16%;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 67%;
                margin-top: 80%;

                & img {
                    height: 80%;
                    margin-left: 5%;
                }
            }
        }

        .modal-rent-input {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 0.1rem solid $dark-color-100;
            border-radius: 2vw;
            background-color: $light-color-100;
            margin-left: 60%;
            margin-top: 37%;
            height: 15%;
            width: 35%;
            font-family: Montserrat Alternates;
            color: $dark-color-100;
            font-size: 3vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }
        .modal-rent-input.error {
            animation: blink-error 1.5s;
        }
        @keyframes blink-error {
            0%, 100% {
                border-color: red;
            }
            50% {
                border-color: transparent;
            }
        }

        .modal-button-yes {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 50%;
            margin-top: 51%;
            border: none;
            border-radius: 1.8vw;
            background-color: $blue-color-100;
            font-family: Montserrat Alternates;
            color: white;
            font-size: 3.5vw;
            width: 28%;
            height: 14%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            cursor: pointer;

            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &.disabled {
                opacity: 0.6;
                pointer-events: none;
            }
            .loading-loading { 
                @include load-animation;
                margin: 0;
                padding: 0;
                height: 90%;
                width: 100%;
                background: none;
                margin-top: 5%;
            }

        }

        .modal-rent-ton-img {
            position: absolute;
            display: flex;
            height: 14%;
            top: 60%;
            left: 51%;
        }

    }
}