
@import '../../variables';

.sell-modal-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
        }

        .lot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96%;
            height: 91%;
            margin-top: 7%;

            .lot-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 100%;

                .img-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    aspect-ratio: 1/1.2;
                    background-color: $light-color-100;
                    border-radius: 3vw;

                    & img {
                        width: 90%;
                    }

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 25%;
                        background-color: $blue-color-100;
                        border-radius: 0 0 3vw 3vw;
                        color: white;
                        font-size: 5vw;
                    }
                }

                .minimum {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    height: 20%;
                    margin-top: 5%;
                    background-color: $light-color-100;
                    border-radius: 3vw;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                }
            }

            .lot-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 100%;

                .sell-bar {
                    position: relative;
                    display: flex;
                    width: 96%;
                    height: 13%;
                    border-radius: 2vw;
                    background-color: $lightest-color-100;
                    text-align: center;
                    overflow: hidden;
                    align-items: center;

                    .sell-bar-fill {
                        display: flex;
                        height: 100%;
                        border-radius: 1.5vw;
                        background-color: rgb(173,255,0);
                        transition: width 0.3s ease;
                    }
                    
                    .sell-bar-text {
                        position: absolute;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $dark-color-100;
                        font-weight: 700;
                        font-size: 3.5vw;
                    }
                }

                .sell-amount {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 14%;
                    margin-top: 4%;

                    .sell-minus {
                        position: relative;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
    
                    .sell-input {
                        position: relative;
                        width: 40%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $lightest-color-100;
                        border: none;
                        border-radius: 2vw;
                        color: $dark-color-100;
                        font-weight: 700;
                        font-size: 3.5vw;
                        text-align: center;
                        margin-left: 4%;
                        margin-right: 4%;
                    }
    
                    .sell-plus {
                        position: relative;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
                }

                .sell-info-boxes {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;
                    height: 23%;
                    margin-top: 4%;

                    .current-price-sell-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        background-color: $light-color-100;
                        width: 48%;
                        height: 100%;
                        border-radius: 2vw;
                        color: $dark-color-100;

                        .title-price {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 25%;
                            font-size: 2.6vw;
                            font-weight: 700;
                        }

                        .current-price {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 28%;
                            font-size: 3vw;
                            margin-top: 2%;

                            & img {
                                height: 120%;
                            }
                        }
    
                        .current-price-sell {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 28%;
                            font-size: 2.7vw;
                            margin-top: 2%;

                            & img {
                                margin-left: 3%;
                                height: 100%;
                            }
                        }
                    }
    
                    .your-price-sell-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        background-color: $light-color-100;
                        width: 48%;
                        height: 100%;
                        border-radius: 2vw;
                        color: $dark-color-100;
    
                        .title-price {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 25%;
                            font-size: 2.6vw;
                            font-weight: 700;
                        }

                        .your-price {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 28%;
                            font-size: 3vw;
                            margin-top: 2%;

                            & img {
                                height: 120%;
                            }
                        }
    
                        .your-price-sell {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 28%;
                            font-size: 2.7vw;
                            margin-top: 2%;

                            & img {
                                margin-left: 3%;
                                height: 100%;
                            }
                        }
                    }
                }

                .sell-box-v2 {
                    display: flex;
                    align-items: center;
                    background-color: $light-color-100;
                    width: 96%;
                    height: 22%;
                    margin-top: 3%;
                    border: none;
                    border-radius: 2vw;
                    text-align: center;
                    font-size: 2vw;
                    color: $dark-color-100;

                    .price-img-input {
                        display: flex;
                        width: 12%;
                        aspect-ratio: 1/1;
                        margin-left: 3%;
                    }

                    .input-price-v2 {
                        display: flex;
                        width: 45%;
                        height: 60%;
                        margin-left: 3%;
                        background-color: white;
                        border: none;
                        border-radius: 1.5vw;
                        color: $dark-color-100;
                        font-family: Montserrat Alternates;
                        font-size: 2.7vw;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                        &::placeholder { 
                            font-size: 2.5vw; 
                        }
                    }

                    .button-sell-v2 {
                        display: flex;
                        background-color: $blue-color-100;
                        margin-left: 3%;
                        width: 28%;
                        height: 60%;
                        border: none;
                        border-radius: 1.5vw;
                        text-align: center;
                        justify-content: center;
                        font-size: 2.4vw;
                        color: white;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        
                        &:active {
                            transform: scale(0.95);
                        }
                        &.disabled {
                            color: $dark-color-100;
                            background-color: white;
                            opacity: 0.5;
                            pointer-events: none;
                            &:active {
                                transform: none;
                            }
                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    background-color: white;
                                }
                            }
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $hover-blue;
                            }
                        }

                        .loading-animation-selling { 
                            @include load-animation;
                            padding: 0;
                            height: 100%;
                            width: 100%;
                            margin-top: 17%;
                            background: none;
                        }
                    }
                }
            }
        }
    }
}
