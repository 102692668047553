
@import '../../../variables';

.upgrade-rig-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .lvls {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 99%;
            height: 50%;

            .lvl-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 43%;
                height: 85%;
                background-color: $light-color-100;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .header-lvl {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;
                    height: 30%;

                    & span {
                        color: $dark-color-100;
                        font-size: 3vw;
                    }

                    .lvl {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 5%;
                        height: 90%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        color: white;
                        font-size: 2.5vw;
                        border-radius: 1.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }

                .parameter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 60%;

                    .img-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        border-radius: 2vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                        & img {
                            height: 75%;
                        }
                    }

                    .amount-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 48%;
                        height: 48%;
                        background-color: white;
                        border-radius: 0 2vw 2vw 0;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        color: $dark-color-100;
                        font-size: 3.2vw;

                        & img {
                            height: 70%;
                        }
                    }
                }
            }

            .arrow {
                position: absolute;
                transform: rotate(90deg);
                width: 13vw;
                margin-bottom: 13%;
            }
        }


        .upgrade-box {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 90%;
            height: 44%;
            background-color: $light-color-100;
            margin-top: 2%;
            border-radius: 3vw;

            .elements {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: start;
                height: 90%;
                width: 60%;

                .element {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;
                    height: 38%;
                    margin: 0 2%;

                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2vw;
                        height: 92%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;

                        & img {
                            height: 100%;
                        }
                    }
                    

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50%;
                        height: 60%;
                        margin-top: 2.5%;
                        background-color: white;
                        border-radius: 0 1.5vw 1.5vw 0;
                        color: $dark-color-100;
                        font-size: 3.3vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }
            }

            .upgrade-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                height: 32%;
                border: none;
                padding: 0;
                border-radius: 2vw;
                background-color: $blue-color-100;
                cursor: pointer;
                color: white;
                font-size: 3.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
                &.disabled {
                    opacity: 0.7;
                    pointer-events: none;
                }

                .loading-animation{ 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    background: none;
                    margin-top: 10%;
                }
            }
        }
    }
}
