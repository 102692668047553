
@import '../../../variables';

.cont-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: 13vw;
    margin-top: 1%;

    .cont-back-button { 
        display: flex;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        color: $dark-color-100;
        font-size: 3vw;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 60%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
        & img {
            height: 90%;
        }
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .cont-title { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 100%;
        color: $white-100;
        font-size: 5vw;
        font-weight: 700;
    }

    .buy-cont-button {
        position: relative;
        display: flex;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        color: $dark-color-100;
        font-size: 3vw;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 60%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }
}

.containers-box { 
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    border: none;
    overflow-y: auto;
    margin-top: 1%;

    .loading-animation-conts {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; 
        width: 25%;
        margin-top: 25%;

        @include load-animation;
    }

    .container-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 35vw;
        border-radius: 2vw;
        background-color: $light-color-100;
        margin-bottom: 5%;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .loading-animation-cont {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60%; 
    
            @include load-animation;
        }

        .left-side {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 40%;

            & img {
                height: 84%;
                border-radius: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .cont-level-box { 
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20%;
                width: 30%;
                border-radius: 1.5vw;
                background-color: rgba(255, 255, 255, 0.8);
                color: $dark-color-100;
                font-size: 3vw;
                font-weight: 700;
                margin-left: 44%;
                margin-top: 56%;
            }

            .amount-cont { 
                position: absolute;
                display: flex;
                font-size: 4vw;
                font-weight: 700;
                color: white;
                border-radius: 999%;
                background-color: $blue-color-100; 
                transform: translateY(-50%);
                margin-bottom: 43%;
                margin-left: 75%;
                width: 25%;
                aspect-ratio: 1/1;
                justify-content: center;
                align-items: center;
            }
        }

        .right-side {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 60%;

            .cont-resources-box { 
                color: $dark-color-100;
                font-size: 3.8vw;
                font-weight: 700;
            }

            .cont-upgrade-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25%;
                width: 60%;
                border: none;
                padding: 0;
                border-radius: 2vw;
                margin-top: 7%;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.8vw;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
        }
    }
}
