@import '../../variables';

.header {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 16vw;
    width: 100vw;
    border: none;
    background-color: $dark-color-100;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.40);
    transition: opacity 0.12s ease-in-out;

    .header-profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 13%;
        aspect-ratio: 1/1;
        background-color: $light-color-100;
        border-radius: 2vw;
        margin-left: 5%;
        cursor: pointer;
        border: none;
        padding: 0;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .header-avatar {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80%;
            width: 100%;
    
            & img {
                height: 100%;
            }

            .header-lvl {
                position: absolute;
                display: flex;
                background-color: rgba(17, 45, 78, 0.7);
                align-items: center;
                justify-content: left;
                color: white;
                font-weight: 700;
                font-size: 3.2vw;
                width: 50%;
                height: 55%;
                margin-top: 40%;
                margin-right: 50%;
                border-radius: 0 999vw 0 0;

                & span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    margin-left: 10%;
                }
            }
        }
    
        .header-lvl-bar {
            position: relative;
            display: flex;
            width: 100%;
            height: 20%;
            border-radius: 0 0 1.5vw 1.5vw;
            background-color: white;
            text-align: center;
            overflow: hidden;
            align-items: center;
    
            .header-lvl-bar-fill {
                display: flex;
                height: 100%;
                background-color: rgb(173,255,0);
                transition: width 0.3s ease;
            }
            
            .header-lvl-bar-text {
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $dark-color-100;
                font-weight: 700;
                font-size: 2vw;
            }
        }
    }

    .header-name-box {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        text-align: start;
        width: 24%;
        height: 80%;
        margin-left: 2%;
        overflow-x: hidden;

        .header-clan {
            display: flex;
            height: 40%;
            justify-content: left;
            align-items: center;
            text-wrap: nowrap;
            padding: 0;
            border: none;
            background: none;
            color: rgb(240, 224, 0);
            font-weight: 700;
            font-size: 3vw;
            cursor: pointer;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: rgb(172, 160, 2);
                }
            }
        }

        .header-firstname {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            height: 30%;
            text-wrap: nowrap;
            color: white;
            font-weight: 700;
            font-size: 3vw;
            margin-top: 5%;
            margin-bottom: 1%;
        }

        .header-lastname {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            height: 30%;
            text-wrap: nowrap;
            color: white;
            font-weight: 700;
            font-size: 3vw;
        }
    }

    .header-technologies {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        margin-left: 2%;
        width: 34.5%;
        
        .technology {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            aspect-ratio: 1/1;
            margin: 1%;
            border: none;
            cursor: pointer;
            border-radius: 1.3vw;
            background-color: $blue-color-100;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            & img {
                opacity: 0.3;
                height: 85%;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }

    .header-store-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $blue-color-100;
        border: none;
        border-radius: 2vw;
        margin-left: 2%;
        width: 13%;
        aspect-ratio: 1/1;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue; 
            }
        }

        .header-store-button1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            & img {
                width: 85%;
            }
        }

        .header-store-button2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            & img {
                width: 35%;
            }
        }
    }

}