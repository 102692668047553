
@import '../../variables';

.levelup-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 15;

    .box {
        display: flex;
        flex-direction: column;
        width: 75vw;
        height: 55vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-weight: 700;
            font-size: 3.7vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .text1 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            margin-top: 12%;
            color: $dark-color-100;
            font-size: 3vw; 
        }

        .text12 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            margin-top: 2%;
            color: $dark-color-100;
            font-size: 3vw; 
        }

        .voyage {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;

            & img {
                width: 75%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                border-radius: 2vw;
            }

            .name {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(255,255,255,0.8);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 3.5vw; 
                width: 30%;
                height: 8vw;
                margin-top: 21%;
                margin-right: 24%;
            }
        }

        .text2 {
            margin-top: 15%;
            color: $dark-color-100;
            font-weight: 700;
            font-size: 3vw; 
        }

        .lvl2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            aspect-ratio: 1/1;
            background-color: $light-color-100;
            margin-top: 5%;
            border-radius: 3vw;
            color: $dark-color-100;
            font-weight: 700;
            font-size: 15vw;
        }

        // Фейерверк
        $particles: 60;
        $width: 500;
        $height: 400;
        $box-shadow: ();
        $box-shadow2: ();
        @for $i from 0 through $particles {
        $box-shadow: $box-shadow,
                    random($width)-$width / 2 + px
                    random($height)-$height / 1.2 + px
                    hsl(random(360), 100, 50);
        $box-shadow2: $box-shadow2, 0 0 #fff
        }
        @mixin keyframes ($animationName) {
            @-webkit-keyframes #{$animationName} {
                @content;
            }
    
            @-moz-keyframes #{$animationName} {
                @content;
            }
    
            @-o-keyframes #{$animationName} {
                @content;
            }
    
            @-ms-keyframes #{$animationName} {
                @content;
            }
    
            @keyframes #{$animationName} {
                @content;
            }
        }
    
        @mixin animation-delay ($settings) {
            -moz-animation-delay: $settings;
            -webkit-animation-delay: $settings;
            -o-animation-delay: $settings;
            -ms-animation-delay: $settings;
            animation-delay: $settings;
        }
    
        @mixin animation-duration ($settings) {
            -moz-animation-duration: $settings;
            -webkit-animation-duration: $settings;
            -o-animation-duration: $settings;
            -ms-animation-duration: $settings;
            animation-duration: $settings;
        }
    
        @mixin animation ($settings) {
            -moz-animation: $settings;
            -webkit-animation: $settings;
            -o-animation: $settings;
            -ms-animation: $settings;
            animation: $settings;
        }
    
        @mixin transform ($settings) {
            transform: $settings;
            -moz-transform: $settings;
            -webkit-transform: $settings;
            -o-transform: $settings;
            -ms-transform: $settings;
        }
    
        .pyro > .before, .pyro > .after {
            left: 10%;
            top: 50%;
            z-index: 100;
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            box-shadow: $box-shadow2;
            @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
        }
                
        .pyro > .after {
            @include animation-delay((1.25s, 1.25s, 1.25s));
            @include animation-duration((1.25s, 1.25s, 6.25s));
        }
                    
        @include keyframes(bang) {
            to {
                box-shadow:$box-shadow;
            }
        }
                
            @include keyframes(gravity)  {
            to {
                @include transform(translateY(200px));
                opacity: 0;
            }
        }
                
        @include keyframes(position) {
            0%, 19.9% {
                margin-top: 10%;
                margin-left: 40%;
            }
            20%, 39.9% {
                margin-top: 40%;
                margin-left: 30%;
            }
            40%, 59.9% {  
                margin-top: 20%;
                margin-left: 70%
            }
            60%, 79.9% {  
                margin-top: 30%;
                margin-left: 20%;
            }
            80%, 99.9% {  
                margin-top: 30%;
                margin-left: 80%;
            }
        }
    }
}
