
@import '../../variables';

@mixin port-marker {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $light-color-100;
    width: 13%;
    aspect-ratio: 1/1;
    border: solid 2px $dark-color-100;
    border-radius: 999vw;
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $hover-light;
        }
    }

    &:active {
        transform: scale(0.95);
    }

    .port-marker-image {
        width: 70%;
        aspect-ratio: 1/1;
        margin-bottom: 10%;
    }

    .port-marker-name {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-wrap: wrap;
        line-height: 1;
        width: 140%;
        height: 45%;
        background-color: $dark-color-100;
        border-radius: 1.5vw;
        color: white;
        overflow: hidden;
        font-size: 2.5vw;
        font-weight: 500;
        margin-top: 105%;
    }
}

.port-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    opacity: 1;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    will-change: transform;
    overflow-x: hidden;
    //overflow-y: hidden;

    &.fade-out {
        opacity: 0;
        transform: scale(0.95);
    }

    &.fade-in {
        opacity: 1;
        transform: scale(1);
    }

    .port-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow-y: auto;

        .city {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%; 
            height: auto;
            margin-top: 3%;
        
            .port-image {
                width: 90%;
                height: 100%;
                border-radius: 4vw;
            }
        
            #port-marker-cont {
                @include port-marker;
                left: 14%;
                top: 67%;
        
            }
            #port-marker-ship {
                @include port-marker;
                left: 73%;
                top: 63%;
            }
            #port-marker-rent {
                @include port-marker;
                left: 22%;
                top: 40%;
            }
            #port-marker-info {
                @include port-marker;
                left: 40%;
                top: 18%;
            }
            #port-marker-store {
                @include port-marker;
                left: 43%;
                top: 60%;
            }
            #port-marker-workshop {
                @include port-marker;
                left: 63%;
                top: 41%;
            }
        }

        .voyages-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3% 0;
            width: 90%;
            border: none;
            background-color: $orange;
            min-height: 7.5vw;
            border-radius: 1.5vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            cursor: pointer;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;

            & img {
                height: 80%;
                margin-right: 1%;
            }

            &:active {
                transform: scale(0.95);
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-orange;
                }
            }
        }
    }
}