
@import '../../variables';

.header-store-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    margin-top: 15.5vw;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .header-store-box {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        width: 100vw;
        height: 50vw;
        border: none;
        background-color: white;
        border-radius: 0 0 3vw 3vw;
        
        .header-store-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 14%;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
        }

        .header-store-page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 83%;
            background-color: none;
            margin-top: 1%;

            .header-store-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                border: none;
                background: none;
                cursor: pointer;

                & img {
                    width: 80%;
                }

                &:active {
                    & img {
                        width: 75%;
                    }
                }
            }

            .header-store-items-box {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: center;
                width: 70%;
                height: 94%;
                margin-bottom: 3%;
                background-color: $light-color-100;
                overflow-y: auto;
                border-radius: 2vw;
                padding-top: 1%;

                .header-store-cell {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    aspect-ratio: 1/1.2;
                    margin: 1.5%;
                    background-color: white;
                    border: solid $dark-color-100 2px;
                    border-radius: 2vw;
                    box-sizing: border-box;

                    .header-store-cell-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 70%;

                        & img {
                            height: 100%;
                        }
                    }
                    
                    .header-store-cell-value {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 30%;
                        color: white;
                        font-size: 2.2vw;
                        font-weight: 500;
                        background-color: $blue-color-100;
                        border-radius: 0 0 1.35vw 1.35vw;
                        border-top: solid $dark-color-100 2px;
                        box-sizing: border-box;

                        &.full {
                            color: rgb(255, 0, 0);
                            font-weight: 700;
                        }
                    }
                }
            }

            .header-store-right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                border: none;
                background: none;
                cursor: pointer;

                & img {
                    width: 80%;
                }

                &:active {
                    & img {
                        width: 75%;
                    }
                }
            }
        }

        

    }
}