
@import '../../variables';

@mixin profile-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: rgba(219, 226, 239, 0.5);
    border-radius: 2vw;
    width: 15vw;
    aspect-ratio: 1/1;
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    margin-top: 4%;

    & img {
        width: 90%;
        aspect-ratio: 1/1;
    }
    &:active {
        transform: scale(0.95);
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: rgba(194, 201, 212, 0.5);
        }
    }
}

.home {  
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    position: relative;

    .fon {
        position: absolute;
        transform: translateX(-50%);
        top: 0;
        left: 50%;
        width: auto;
        height: 100%;
        object-fit: cover;
        overflow-x: hidden;
    }

    .ava {
        position: absolute;
        transform: translateX(-50%);
        bottom: 4%;
        left: 50%;
        height: 55%;
    }

    .top-buttons {
        display: flex;
        width: 90%;
        margin-top: 3%;
        height: 60vw;
        z-index: 2;

        .ref-tut-buttons {
            #referal-button { 
                @include profile-button; 
            }
    
            .profile-tutorial-button {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: rgba(219, 226, 239, 0.5);
                border-radius: 2vw;
                width: 15vw;
                aspect-ratio: 1/1;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                margin-top: 30%;
    
                & img {
                    width: 90%;
                }
    
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba(194, 201, 212, 0.5);
                    }
                }
            }
    
            .profile-flotilias-button {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: rgba(219, 226, 239, 0.5);
                border-radius: 2vw;
                width: 15vw;
                aspect-ratio: 1/1;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                margin-top: 30%;
    
                & img {
                    width: 95%;
                }
    
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba(194, 201, 212, 0.5);
                    }
                }
            }
        }

        .lang-wallet-buttons {
            margin-left: 68%;
            .settings-button {
                @include profile-button; 
            }

            .ton-button-profile {
                @include profile-button; 
                margin-top: 30%;
            }

            .rating-button {
                @include profile-button; 
                margin-top: 30%;
            }
        }
    }

    .mini-game-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 2.5%;
        left: 5%;
        width: 20vw;
        aspect-ratio: 1/1;
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        &:active {
            transform: scale(0.95);
        }

        &.disabled {
            pointer-events: none;
        }

        & img {
            width: 100%;
            object-fit: contain;
            border-radius: 2vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .mini-game-timer {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5vw;
            width: 100%;
            height: 100%;
            border: 2px solid black;
            padding: 0;
            background-color: rgba(0, 0, 0, 0.82);
            border-radius: 2.5vw;
            color: white;
            font-size: 4.3vw;
            font-weight: 700;
        }

        .mini-game-name {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5vw;
            width: 90%;
            height: 35%;
            padding: 0;
            background-color: rgba(255,255,255,0.85);
            border-radius: 2vw;
            color: $dark-color-100;
            font-size: 2.8vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .loading-animation { 
            @include load-animation;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 35%;
            padding: 0;
            margin: 0;
            background-color: rgba(255,255,255,0.85);
            border-radius: 2vw;
        }
    }

    .tasks-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: rgba(219, 226, 239, 0.5);
        border-radius: 2vw;
        width: 20vw;
        aspect-ratio: 1/1;
        bottom: 2.5%;
        right: 5%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        margin-top: 4%;

        & img {
            width: 100%;
            aspect-ratio: 1/1;
        }
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: rgba(194, 201, 212, 0.5);
            }
        }

        .amount-tasks {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $orange;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
            border-radius: 99vw;
            width: 40%;
            height: 30%;
            margin-bottom: 60%;
            margin-left: 55%;
        }
    }
}

.profile-tutorial-overlay {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,0.82);
    z-index: 3;

    .profile-tutorial-box {
        display: flex;
        width: 88%;
        height: 27vw;
        margin-left: 6%;
        margin-top: 60%;

        .profile-tutorial-tell-box {
            display: flex;
            flex-direction: column;
            background-color: white;
            height: 100%;
            width: 100%;
            border-radius: 5vw;

            & span {
                color: $dark-color-100;
                font-size: 4vw;
                width: 70%;
                margin-top: 7%;
                margin-left: 4%;
                font-weight: 700;
            }
            
            .profile-tutorial-yes {
                position: absolute;
                width: 27%;
                height: 12vw;
                border: none;
                background-color: $blue-color-100;
                border-radius: 3vw;
                color: white;
                font-size: 4vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                top: 81vw;
                left: 14vw;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
            }

            .profile-tutorial-no {
                position: absolute;
                width: 27%;
                height: 12vw;
                border: none;
                background-color: $blue-color-100;
                border-radius: 3vw;
                color: white;
                font-size: 4vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                top: 81vw;
                left: 60vw;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
            }
        }

        .profile-tutorial-man {
            position: absolute;
            height: 43vw;
            right: 2vw;
            top: 35vw;
        }
    } 
}

.modal-wallet-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .modal-wallet-content {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 42vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 25%;
        align-items: center; 

        .modal-wallet-title {
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            margin-top: 3%;
        }

        .modal-wallet-info {
            width: 90%;
            text-align: center;
            color: $dark-color-100;
            font-size: 3.5vw;
            font-weight: 500;
            margin-top: 2%;
        }

        .modal-wallet-wallet {
            width: 90%;
            text-align: center;
            color: $dark-color-100;
            font-size: 7vw;
            font-weight: 700;
            margin-top: 2%;
        }

        .modal-wallet-buttons {
            width: 90%;
            height: 30%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            & button {
                width: 35%;
                height: 70%;
                background-color: $blue-color-100;
                border: none;
                border-radius: 2vw;
                color: white;
                font-size: 3.2vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
        }
    }
}
