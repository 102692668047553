
@import '../../variables';

@mixin voyage-ship-info {
    position: relative;
    height: 90%;
    width: 53%;
    transition: transform 0.3s, opacity 0.3s;
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        height: 100%; 
        border-radius: 2vw;
        border: solid $dark-color-100 2px;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    }

    .ship-item-name {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.8);
        border-radius: 1vw;
        height: 18%;
        width: 72%;
        left: 8%;
        top: 5%;
        font-size: 2.5vw;
        font-weight: 700;
        color: $dark-color-100;
    }

    .ship-item-lvl {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.8);
        border-radius: 1vw;
        height: 18%;
        width: 35%;
        left: 58%;
        top: 77%;
        font-size: 2.5vw;
        font-weight: 700;
        color: $dark-color-100;
    }

    .ship-item-capacity {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.8);
        border-radius: 1vw;
        height: 18%;
        width: 35%;
        left: 8%;
        top: 77%;
        font-size: 3vw;
        font-weight: 700;
        color: $dark-color-100;

        & img {
            margin-left: 5%;
            height: 90%;
            width: auto;
            border: none;
            box-shadow: none;
        }

    }
}

.voyage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    height: 100%;
    overflow: hidden;

    .map {
        position: relative;
        display: flex;
        width: 90vw; 
        height: 94%; 
        border-radius: 4vw;
        overflow-x: auto;
        cursor: grab;

        .map-image {
            height: 100%;
            object-fit: contain;
        }

        .button-back-port {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 17%;
            height: 6%;
            margin-top: 3%;
            margin-left: 3%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            cursor: pointer;
            border: none;
            padding: 0;
            border-radius: 2vw;
            background-color: white;
            color: $dark-color-100;
            font-size: 3.5vw;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: rgb(240, 240, 240);
                }
            }
            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 80%;
                margin-right: 2%;
            }
        }

        .loading-info { 
            position: fixed;
            display: flex;
            width: 100%; 
            height: 100%; 
            top: 4.2%;
            left: 84%;
    
            @include load-animation;
        }

        .country {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: none;
            width: 15vw;
            aspect-ratio: 1/1;
            cursor: pointer;
            padding: 0;

            .country-marker {
                position: absolute;
                height: 100%;
                width: auto;
            }

            .country-marker-img {
                height: 48%;
                margin-top: -25%;
                z-index: 2;
            }

            .rotating {
                margin-left: 1.5%;
                animation: rotate 2.5s linear infinite;
            }

            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }

            &:active {
                transform: scale(0.95);
            }

            .country-marker-timer {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 145%;
                width: 13vw; 
                height: 4vw; 
                border-radius: 1vw;
                background-color: white;
                color: $dark-color-100;
                font-size: 2.6vw;
                font-weight: 700;

                .country-marker-count {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $blue-color-100;
                    border-radius: 99%;
                    height: 100%;
                    aspect-ratio: 1/1;
                    color: white;
                    font-size: 2.2vw;
                    font-weight: 700;
                    margin-bottom: 35%;
                    margin-left: 100%;
                }
            }

            .loading-animation-country-marker {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 195%;
                width: 13vw; 
                height: 4vw; 
                border-radius: 1vw;
                background-color: white;
                color: $dark-color-100;
                font-size: 2.6vw;
                font-weight: 700;
                @include load-animation;
            }

            &:nth-child(14), &:nth-child(15) {
                .wave {
                    width: 30%;
                    height: 30%;
                    border-radius: 100%;
                    border: 6px solid #e5ff00;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    animation: ring 1.5s infinite;
                }
                
                @keyframes ring {
                    0% {
                        width: 5vw;
                        height: 5vw;
                        opacity: 1;
                    }
                    100% {
                        width: 20vw;
                        height: 20vw;
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.country-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100vw; 
    min-height: 100%;
    overflow-y: hidden;
    top: 0;
    border: none;
    background-color: $dark-color-100;
    z-index: 10;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;

    .country-modal-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90vw;
        height: 10vw;
        margin-top: 3%;

        .button-box-country {
            display: flex;
            justify-content: start;
            align-items: center;
            width: 20%;
            height: 100%;
            
            .back-button-coutry {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 85%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;
                border: none;
                padding: 0;
                border-radius: 2vw;
                background-color: white;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-light;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
                & img {
                    height: 80%;
                }
            }
        }
        

        .country-modal-title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin-right: 20%;
            height: 50%;
            font-size: 4.5vw;
            color: white;
            font-weight: 700;
        }

    
        .sended-button {
            position: absolute;
            display: flex;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            align-items: center;
            justify-content: center;
            width: 17vw;
            height: 7vw;
            cursor: pointer;
            z-index: 15;
            top: 4.5vw;
            right: 5vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            font-size: 2.5vw;
            color: $dark-color-100;
            font-weight: 700;
    
            &:active {
                transform: scale(0.95);
            }
    
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }
    }

    .country-modal-content {
        display: flex;
        align-items: start;
        justify-content: center;
        overflow-y: auto;
        overflow-x: hidden;

        .loading-animation{ 
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%; 
            height: 100%; 
            margin-top: 20vw;
    
            @include load-animation;
        }
    
        .country-modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%; 
            height: 100%;
    
            .country-box {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: none;
                border-radius: 3vw;
                width: 100%;
                height: 50vw;
                margin-top: 3%;
    
                .country-img {
                    height: auto;
                    height: 100%;
                    border-radius: 3vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                }
    
                .country-name {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    line-height: 0.9;
                    background-color: rgba(255,255,255,0.8);
                    width: 34%;
                    height: 15%;
                    border-radius: 2vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    font-weight: 900;
                    margin-top: 43%;
                    margin-right: 61%;
                }
    
                .country-timer {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255,255,255,0.8);
                    width: 20%;
                    height: 15%;
                    border-radius: 2vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    font-weight: 900;
                    margin-bottom: 43%;
                    margin-right: 74%;
    
                    & img {
                        height: 80%;
                        margin-right: 5%;
                    }
                }
    
                .country-xp {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255,255,255,0.8);
                    width: 18%;
                    height: 15%;
                    border-radius: 2vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    font-weight: 900;
                    margin-bottom: 43%;
                    margin-right: 30%;
    
                    & img {
                        height: 80%;
                        margin-right: 5%;
                    }
                }
    
                .country-resources {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    width: 24%;
                    height: 92%;
                    margin-left: 69%;
    
                    .country-resource-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 20%;
                        margin-top: 3%;
    
                        .country-resource-amount {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(255,255,255,0.8);
                            width: 66%;
                            height: 70%;
                            border-radius: 1.5vw 0 0 1.5vw;
                            color: $dark-color-100;
                            font-size: 2.4vw;
                            font-weight: 900;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        }
        
                        .country-resource-img-box {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(255,255,255,0.8);
                            height: 90%;
                            aspect-ratio: 1/1;
                            border-radius: 1.5vw;
                            color: $dark-color-100;
                            font-size: 2.8vw;
                            font-weight: 900;
                            margin-left: -0.5%;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                            & img {
                                height: 95%; 
                            }
                        }
                    }
                }
                .country-temp-term {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background-color: rgba(255,255,255,0.8);
                    width: 18%;
                    height: 15%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    font-weight: 900;
                    margin-top: 43%;
                    margin-right: 4%;
                }
                .country-temp-info {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border: none;
                    background-color: $orange;
                    width: 8.5%;
                    aspect-ratio: 1/1;
                    border-radius: 1.5vw;
                    color: white;
                    font-size: 5.5vw;
                    font-weight: 900;
                    margin-top: 43%;
                    margin-left: 41%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    cursor: pointer;
    
                    &:active {
                        transform: scale(0.95);
                    }
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: #af741a;
                        }
                    }
                }

                .event-points {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background-color: rgba(255,255,255,0.8);
                    width: 20%;
                    height: 15%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    font-weight: 900;
                    margin-top: 43%;
                    margin-left: 74%;
                }
    
                .country-temp-rating {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border: none;
                    background-color: $blue-color-100;
                    width: 8.5%;
                    aspect-ratio: 1/1;
                    border-radius: 1.5vw;
                    color: white;
                    font-size: 5vw;
                    font-weight: 900;
                    margin-top: 43%;
                    margin-left: 85%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    cursor: pointer;
    
                    &:active {
                        transform: scale(0.95);
                    }
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }
    
                    & img {
                        height: 85%;
                    }
                }
            }
    
            .country-low-lvl {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 100%;
                height: 30vw;
                margin-top: 5%;
                font-size: 3.8vw;
                font-weight: 900;
                color: $dark-color-100;
    
                .country-need-lvl {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $blue-color-100;
                    width: 16%;
                    aspect-ratio: 1/1;
                    border: none;
                    border-radius: 2vw;
                    font-size: 6vw;
                    font-weight: 900;
                    color: white;
                    margin-top: 4%;
                }
            }
    
            .not-ships {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 100%;
                height: 20vw;
                margin-top: 5%;
                font-size: 4vw;
                font-weight: 900;
                color: $dark-color-100;
    
                & span {
                    width: 70%;
                    text-align: center;
                    line-height: 1.7;
                }
            }

            .event-done, .event-ended, .event-not-started {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 90vw;
                height: 20vw;
                margin-top: 5%;
                font-size: 3.5vw;
                color: $dark-color-100;
    
                & span {
                    width: 90%;
                    text-align: center;
                    line-height: 1.5;
                }
            }
    
            .country-ship-box {
                position: relative;
                display: flex;
                align-items: center;
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 100%;
                height: 30vw;
                margin-top: 3%;
    
                .ship-slider {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    height: 100%;
    
                    &.single-ship {
                        .ship-item {
                            @include voyage-ship-info;
                        }
                    }
                
                    &.two-ships {
                        .ship-item {
                            @include voyage-ship-info;
    
                            &:nth-child(1) {
                                margin-right: -45%;
                                z-index: 12;
                            }
                            &:nth-child(2) {
                                margin-left: 20%;
                                z-index: 11;
                            }           
                        }
                    }
                
                    &.three-or-more-ships {
                        margin-left: 3%;
                        .ship-item {
                            @include voyage-ship-info;
        
                            &:nth-child(1) {
                                margin-right: -25%;
                                z-index: 11;
                            }
                            &:nth-child(2) {
                                z-index: 12;
                            }
                            &:nth-child(3) {
                                margin-left: -25%;
                                z-index: 11;
                            }          
                        }
                    }
                }
                
                .country-ship-capacity {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    height: 30%;
                    width: 29%;
                    top: 9%;
                    left: 67%;
    
                    .country-ship-capacity-amount {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $blue-color-100;
                        border-radius: 1.5vw 0 0 1.5vw;
                        height: 75%;
                        width: 60%;
                        font-size: 4vw;
                        color: white;
                    }
    
                    .country-ship-capacity-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        border-radius: 1.5vw;
                        height: 9vw;
                        width: 9vw;
    
                        & img {
                            height: 80%;
                        }
                    }
                }
                
                .country-ship-button-box {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 1.5vw;
                    height: 48%;
                    width: 29%;
                    top: 45%;
                    left: 67%;
    
                    .country-ship-button-fuel-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50%;
                        font-size: 4vw;
                        color: $dark-color-100;
    
                        & img {
                            height: 85%;
                        }
                    }
    
                    .country-ship-button {
                        height: 50%;
                        width: 100%;
                        border: none;
                        border-radius: 0 0 1.5vw 1.5vw;
                        cursor: pointer;
                        background-color: $blue-color-100;
                        font-size: 3.2vw;
                        color: white;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                        &:active {
                            transform: scale(0.95);
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $hover-blue;
                            }
                        }
    
                        .loading-animation-send {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%; 
                            height: 90%; 
    
                            @include load-animation;
                        }
                    }
                } 
            }

            .country-cont-box {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 90vw;
                height: 65vw;
                margin-top: 3%;
                margin-bottom: 5%;

                .cont-title {
                    display: flex;
                    width: 100%;
                    height: 15%;
                    align-items: center;
                    justify-content: center;

                    .cont-title-text {
                        color: $dark-color-100;
                        font-size: 4vw;
                        font-weight: 700;
                        margin-left: 20%;
                    }

                    .auto-box {
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        height: 100%;
                        width: 20%;

                        .auto-button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 80%;
                            width: 50%;
                            border: none;
                            background: $blue-color-100;
                            border-radius: 1.5vw;
                            cursor: pointer;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                            &:active {
                                transform: scale(0.95);
                            }

                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    background-color: $hover-blue;
                                }
                            }

                            & img {
                                height: 100%;
                            }
                        }
                    }
                }

                .cont-box {
                    display: flex;
                    width: 100%;
                    height: 85%;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                    margin-left: 3%;

                    .country-cont-item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        border-radius: 1.5vw;
                        width: 45%;
                        height: 22%;

                        .img-box {
                            display: flex;
                            height: 100%;
                            
                            .country-cont-item-amount {
                                position: absolute;
                                display: flex;
                                color: $white-100;
                                font-size: 3.3vw;
                                border: none;
                                border-radius: 9999%;
                                background-color: $dark-color-100; 
                                transform: translateY(-50%);
                                margin-top: 1%;
                                margin-left: -5%;
                                width: 5.5vw;
                                height: 5.5vw;
                                justify-content: center;
                                align-items: center;
                            }

                            & img {
                                height: 100%;
                                border-radius: 1.5vw;
                                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                            }
                        }

                        .amount-box {
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            height: 75%;
                            width: 68%;
                            background-color: $blue-color-100;
                            border-radius: 0 1.5vw 1.5vw 0;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                            
                            .country-cont-item-minus {
                                height: 100%;
                                cursor: pointer;
                                border: none;
                                padding: 0;
                                background: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
        
                                & img {
                                    height: 70%;
                                    box-shadow: none;
                                }
        
                                &:active {
                                    transform: scale(0.95);
                                }
                            }
            
                            .country-cont-item-amount-selected {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $light-color-100; 
                                border-radius: 1.5vw;
                                font-size: 4vw;
                                width: 10vw;
                                height: 5.5vw;
                                color: $dark-color-100;
                                font-size: 4vw;
                            }
            
                            .country-cont-item-plus {
                                height: 100%;
                                cursor: pointer;
                                border: none;
                                padding: 0;
                                background: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
        
                                & img {
                                    height: 70%;
                                    box-shadow: none;
                                }
        
                                &:active {
                                    transform: scale(0.95);
                                }
                            }
                        }
        
                    }
                }
            }
    
            /* .country-cont-box {
                position: relative;
                display: grid;
                justify-content: center;
                grid-template-columns: repeat(2, 1fr);
                background-color: $light-color-100;
                border-radius: 3vw;
                width: 100%;
                height: 50vw;
                margin-top: 3%;
                margin-bottom: 5%;
    
                .country-cont-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    background-color: $blue-color-100;
                    border-radius: 1.5vw;
                    margin-left: 10%;
                    margin-top: 7.5%;
                    min-height: 5%;
                    width: 80%;
                    height: 50%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                    & img {
                        height: 130%;
                        border-radius: 1.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
    
                    .country-cont-item-amount {
                        position: absolute;
                        display: flex;
                        color: $white-100;
                        font-size: 3.3vw;
                        border: none;
                        border-radius: 9999%;
                        background-color: $dark-color-100; 
                        transform: translateY(-50%);
                        margin-top: -9%;
                        margin-left: -7%;
                        width: 5.5vw;
                        height: 5.5vw;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .country-cont-item-minus {
                        position: absolute;
                        margin-left: 29%;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 70%;
                            box-shadow: none;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
    
                    .country-cont-item-amount-selected {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $light-color-100; 
                        border-radius: 1.5vw;
                        margin-top: 0%;
                        margin-left: 50.5%;
                        font-size: 4vw;
                        width: 10vw;
                        height: 5.5vw;
                        color: $dark-color-100;
                        font-size: 4vw;
                    }
    
                    .country-cont-item-plus {
                        position: absolute;
                        margin-left: 77.5%;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 70%;
                            box-shadow: none;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
    
                }
            } */
    
        }
    }
}


.country-modal-info-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 101;

    .loading-animation{ 
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; 
        height: 100%; 

        @include load-animation;
    }

    .country-modal-info-box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 120vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 10%;
        align-items: center;

        .country-modal-info-title {
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            margin-top: 2%;
        }

        .close-button {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-color-100;
            width: 22vw;
            height: 7.5vw;
            border: none;
            border-radius: 2vw;
            cursor: pointer;
            margin-top: 110%;
            font-family: Montserrat Alternates;
            color: white;
            font-size: 4vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
        }

        .info-country-box {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3vw;
            width: 95%;
            height: 36%;
            margin-top: 4%;

            .info-country-img {
                height: auto;
                width: 92%;
                border-radius: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .info-country-name {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 0.9;
                background-color: rgba(255,255,255, 0.8);
                width: 34%;
                height: 18%;
                border-radius: 2vw;
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-top: 39%;
                margin-right: 54%;
            }

            .info-country-timer {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255, 0.8);
                width: 20%;
                height: 18%;
                border-radius: 2vw;
                color: $dark-color-100;
                font-size: 3vw;
                margin-bottom: 39%;
                margin-right: 70%;
                
                &.active-voyage {
                    width: 24%;
                    margin-right: 64%;
                }

                & img {
                    height: 80%;
                    margin-right: 5%;
                    
                }
            }

            .info-country-xp {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255, 0.8);
                width: 20%;
                height: 18%;
                border-radius: 2vw;
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-top: 39%;
                margin-left: 68%;

                & img {
                    height: 80%;
                    margin-right: 5%;
                }
            }

            .country-temp-term {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: rgba(255,255,255,0.8);
                width: 24%;
                height: 18%;
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-top: 39%;
                margin-left: 8%;
            }

            .event-points {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: rgba(255,255,255,0.8);
                width: 20%;
                height: 18%;
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 3.5vw;
                margin-bottom: 39%;
                margin-left: 68%;
            }
        }

        .info-ship {
            position: relative;
            display: flex;
            justify-content: center;
            background-color: $blue-color-100;
            border-radius: 3vw;
            border: none;
            width: 88%;
            height: 24vw;
            padding-bottom: 2%;
            padding-top: 2%;
            margin-top: 3%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            .info-ship-item {
                position: relative;
                display: flex;
                height: 100%;
                aspect-ratio: 1/1;
                margin-right: 3%;

                .info-ship-item-name {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255,255,255, 0.8);
                    border-radius: 1vw;
                    height: 20%;
                    width: 70%;
                    left: 5%;
                    top: 4%;
                    font-size: 2.3vw;
                    color: $dark-color-100;
                }
            
                .info-ship-item-lvl {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255,255,255, 0.8);
                    border-radius: 1vw;
                    height: 20%;
                    width: 34%;
                    right: 4%;
                    top: 75%;
                    font-size: 2.3vw;
                    color: $dark-color-100;
                }
            
                .info-ship-item-capacity {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255,255,255, 0.8);
                    border-radius: 1vw;
                    height: 20%;
                    width: 35%;
                    left: 5%;
                    top: 75%;
                    font-size: 2.5vw;
                    color: $dark-color-100;
            
                    & img {
                        box-shadow: none;
                        margin-left: 5%;
                        height: 80%;
                        width: auto;
                    }
                }
            
                & img {
                    height: auto;
                    width: 100%; 
                    border-radius: 2vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                } 
            }

            .info-conts {
                position: relative;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 50%;
                margin-left: 3%;
                align-items: start;
                grid-auto-rows: min-content;
                row-gap: 5%;

                .info-cont-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    margin-left: 5%;
                    margin-top: 0;
                    margin-bottom: 0;

                    & img {
                        height: 7.5vw;
                        width: 7.5vw;
                        border-radius: 1.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }

                    .info-cont-item-amount {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 90%;
                        height: 5vw;
                        font-size: 3.5vw;
                        color: $dark-color-100;
                        border-radius: 0 1.5vw 1.5vw 0;
                        background-color: $light-color-100;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }
            }
        } 

        .info-country-resources {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 88%;
            height: 18%;
            margin-top: 2%;

            .info-country-resource-item {
                display: flex;
                align-items: center;
                width: 90%;
                height: 60%;
                margin-top: 5%;

                .info-country-resource-amount {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-color-100;
                    width: 66%;
                    height: 7vw;
                    border-radius: 0 1.5vw 1.5vw 0;
                    color: $dark-color-100;
                    font-size: 2.5vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                }

                .info-country-resource-img-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $blue-color-100;
                    width: 9vw;
                    height: 9vw;
                    border-radius: 1.5vw;
                    margin-left: -1%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    & img {
                        height: 100%; 
                    }
                }
            }
        }  
    }
}


@keyframes blinkTextRed {
    0%, 100% { color: inherit; }
    50% { color: red; }
}


.modal-enter {
    opacity: 0;
    transform: scale(0.9);
}

.modal-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}