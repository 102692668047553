@import '../../../variables';

.event-subscribe-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.75);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 106;

    .box {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 40vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        bottom: 5%;
        align-items: center;

        .text {
            width: 90%;
            text-align: center;
            margin-top: 3%;
            color: $dark-color-100;
            font-weight: 700;
            font-size: 3.5vw;
        }

        .subscribe {
            margin-top: 6%;
            width: 65%;
            height: 20%;
            padding: 0;
            border: none;
            border-radius: 1.5vw;
            background-color: $blue-color-100;
            color: white;
            font-size: 3.5vw;
            cursor: pointer;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &:active {
                transform: scale(0.95);
            }
        }

        .check {
            margin-top: 3%;
            width: 65%;
            height: 20%;
            padding: 0;
            border: none;
            border-radius: 1.5vw;
            background-color: $orange;
            color: white;
            font-size: 3.5vw;
            cursor: pointer;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                }
            }
            &:active {
                transform: scale(0.95);
            }
            &.loading {
                pointer-events: none;
                opacity: 0.7;
            }
            .loading-animation { 
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%; 
                height: 90%; 
        
                @include load-animation;
            }
        }
    }
}