
@import '../../variables';

.ref-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    min-height: 100%;
    border: none;
    background-color: $dark-color-100;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 15;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;

    .ref-title {
        position: relative;
        display: flex;
        color: white;
        font-size: 6vw;
        font-weight: 700;
        margin-top: 4%;
    }

    .ref-back {
        position: absolute;
        display: flex;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        align-items: center;
        justify-content: center;
        width: 18vw;
        height: 8vw;
        left: 5%;
        top: 2%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        & img {
            height: 90%;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .ref-share-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $light-color-100;
        width: 90%;
        height: 22vw;
        margin-top: 4%;
        border-radius: 2vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .ref-share-box-info {
            color: $dark-color-100;
            font-size: 3.5vw;
            font-weight: 700;
        }

        .ref-share-button {
            display: flex;
            background-color: $blue-color-100;
            border-radius: 1.5vw;
            border: none;
            align-items: center;
            justify-content: center;
            width: 55%;
            height: 45%;
            margin-top: 3%;
            color: white;
            font-size: 4.5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                } 
            }
        }

        .ref-share-buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 90%;
            height: 58%;

            .ref-share-button-copy {
                display: flex;
                background-color: $blue-color-100;
                border-radius: 1.5vw;
                border: none;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 70%;
                margin-top: 3%;
                color: white;
                font-size: 3.5vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    } 
                }
            }

            .ref-share-button-share {
                display: flex;
                background-color: $blue-color-100;
                border-radius: 1.5vw;
                border: none;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 70%;
                margin-top: 3%;
                color: white;
                font-size: 3.5vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
        }
    }

    .ref-info-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-wrap:  nowrap;
        background-color: $light-color-100;
        width: 90%;
        height: 11vw;
        margin-top: 4%;
        border-radius: 2vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        color: $dark-color-100;
        font-size: 3.5vw;
        font-weight: 700;
    }

    .ref-invited-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $light-color-100;
        width: 50%;
        height: 19vw;
        margin-top: 4%;
        border-radius: 2vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        color: $dark-color-100;
        font-weight: 700;

        .ref-invited {
            font-size: 5vw;
        }

        .ref-invited-amount {
            font-size: 8vw;
        }
    }

    .ref-received-box {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $light-color-100;
        width: 90%;
        height: 51vw;
        margin-top: 4%;
        border-radius: 2vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        color: $dark-color-100;
        font-size: 4vw;
        padding-top: 2%;
        margin-bottom: 5vw;

        .ref-received-title {
            font-weight: 700;
        }

        .ref-resources-box {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.5vw;
            width: 90%;
            margin-top: 3%;

            .ref-resource-item {
                display: flex;
                align-items: center;
                
                .ref-resource-img {
                    position: relative;
                    display: flex;
                    background-color: white;
                    width: 9vw;
                    height: 9vw;
                    border-radius: 1.5vw;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    & img {
                        height: 80%;
                        width: auto;
                    }
                }

                .ref-resource-amount {
                    display: flex;
                    width: 55%;
                    height: 75%;
                    background-color: $blue-color-100;
                    display: flex;
                    justify-content: center;
                    border-radius: 0 1.5vw 1.5vw 0;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: white;
                    font-size: 2.7vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                }
            }
        }
    }
}