
@import '../../variables';

.market {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .switch-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: $light-color-100;
        width: 100%;
        min-height: 12vw;
        box-shadow: 
            0px 6px 6px rgba(0, 0, 0, 0.30) inset,
            0px 6px 6px rgba(0, 0, 0, 0.30);
    
        .switch-button {
            width: 40%;
            height: 70%;
            border: none;
            padding: 0;
            background-color: $blue-color-100;
            border-radius: 2vw;
            color: white;
            font-size: 4vw;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &.active {
                background-color: $dark-color-100;
                cursor: default;
                &:active {
                    transform: none;
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $dark-color-100;
                    }
                }
            }
        }
    }
}