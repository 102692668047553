
@import '../../variables';

@mixin nav-button-tutorial {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    border-radius: 999%;
    border: solid $orange 4px;
    background-color: $blue-color-100;
    cursor: pointer;

    & span {
        position: absolute;
        margin-top: 155%;
        color: white;
    }

    & img {
        width: 75%;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $hover-blue;
        }
    }
}

.tutorial-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    z-index: 100;
    background-color: $dark-color-100;
    overflow-y: hidden;

    .tutorial-content-box {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 100%;
        height: 83%;
        overflow-y: hidden;

        .tutorial-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 85%;
            max-height: 85%;
            overflow-y: auto;
            background-color: white;
            border-radius: 3vw;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            padding: 5% 0;
            margin-top: 5%;
            margin-bottom: 0;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            &.visible {
                opacity: 1;
            }
    
            .language-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 90%;
                height: 42vw;
    
                .language-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 15%;
                    font-size: 6vw;
                    color: $dark-color-100;
                    margin-bottom: 7%;
                    font-weight: 700;
                }
    
                .overlay {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 70%;
    
                    & button {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        width: 80%;
                        height: 30%;
                        margin: 2% 0;
                        border: none;
                        cursor: pointer;
                        background-color: $blue-color-100;
                        border-radius: 2vw;
                        color: white;
                        font-size: 4vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        
                        & img {
                            height: 80%;
                            margin-left: 23%;
                            margin-right: 5%;
                        }

                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $hover-blue; 
                            }
                        }

                        &.selected {
                            background-color: $dark-color-100;
                            transform: scale(0.95);
                            pointer-events: none;

                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    background-color: $hover-dark; 
                                }
                            }
                        }

                        
                    }
                }
            }
    
            .ship-box {
                display: flex;
                align-items: start;
                justify-content: center;
                width: 85%;
                aspect-ratio: 1/1;
                margin-bottom: 5%;
    
                .ship-image {
                    width: 100%;
                    border-radius: 3vw;
                    transition: opacity 0.5s ease-in-out;
                    opacity: 0;
    
                    &.visible {
                        opacity: 1;
                    }
                }
    
                .name {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.8);
                    border-radius: 2vw;
                    width: 30%;
                    height: 9vw;
                    color: $dark-color-100;
                    font-size: 5vw;
                    margin-top: 2.5%;
                    margin-right: 37%;
                    transition: opacity 0.5s ease-in-out;
                    opacity: 0;
    
                    &.visible {
                        opacity: 1;
                    }
                }
            }
    
            .text-bold {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 95%;
                color: $dark-color-100;
                font-size: 4vw;
                margin: 3% 0;
            }
    
            .ava-box {
                background-color: white;
                width: 90%;
                height: 70vw;
                border-radius: 5vw;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-wrap: wrap;
                background-color: $blue-color-100;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        
                .ava-button {
                    width: 30%;
                    height: 47%;
                    padding: 0;
                    margin: 0;
                    border: none;
                    background: none;
                    cursor: pointer;
        
                    & img {
                        height: 100%;
                    }
        
                    &.selected {
                        transform: scale(1.15);
                    }
        
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            transform: scale(1.15);
                        }
                    }
        
                    .choose-icon {
                        position: absolute;
                        bottom: 2vw;
                        right: -2vw;
                        height: 40%;
                        aspect-ratio: 1/1;
                    }
                }
            }

            .cont-box {
                display: flex;
                align-items: start;
                justify-content: center;
                width: 85%;
                aspect-ratio: 1/1;
                margin-bottom: 5%;
    
                .cont-image {
                    width: 100%;
                    border-radius: 3vw;
                    transition: opacity 0.5s ease-in-out;
                    opacity: 0;
    
                    &.visible {
                        opacity: 1;
                    }
                }
            }

            .voyages-img {
                border-radius: 4vw;
                width: 90%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .resources-box {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 90%;

                & img {
                    width: 13%;
                }
            }

            .warehouse-img {
                border-radius: 4vw;
                width: 90%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .rig-img {
                border-radius: 4vw;
                width: 90%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .components-img {
                border-radius: 4vw;
                width: 90%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .market-img {
                border-radius: 4vw;
                width: 90%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .logo-img {
                width: 90%;
            }

            .final-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $blue-color-100;
                border: none;
                padding: 0;
                width: 65%;
                height: 12vw;
                border-radius: 2.5vw;
                color: white;
                font-size: 4vw;
                margin-top: 2%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
                &.disabled {
                    pointer-events: none;
                    opacity: 0.8;
                }

                .loading-animation { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 90%;
                    width: 100%;
                }
            }

            // Фейерверк
            $particles: 60;
            $width: 500;
            $height: 400;
            $box-shadow: ();
            $box-shadow2: ();
            @for $i from 0 through $particles {
            $box-shadow: $box-shadow,
                        random($width)-$width / 2 + px
                        random($height)-$height / 1.2 + px
                        hsl(random(360), 100, 50);
            $box-shadow2: $box-shadow2, 0 0 #fff
            }
            @mixin keyframes ($animationName) {
                @-webkit-keyframes #{$animationName} {
                    @content;
                }
        
                @-moz-keyframes #{$animationName} {
                    @content;
                }
        
                @-o-keyframes #{$animationName} {
                    @content;
                }
        
                @-ms-keyframes #{$animationName} {
                    @content;
                }
        
                @keyframes #{$animationName} {
                    @content;
                }
            }
        
            @mixin animation-delay ($settings) {
                -moz-animation-delay: $settings;
                -webkit-animation-delay: $settings;
                -o-animation-delay: $settings;
                -ms-animation-delay: $settings;
                animation-delay: $settings;
            }
        
            @mixin animation-duration ($settings) {
                -moz-animation-duration: $settings;
                -webkit-animation-duration: $settings;
                -o-animation-duration: $settings;
                -ms-animation-duration: $settings;
                animation-duration: $settings;
            }
        
            @mixin animation ($settings) {
                -moz-animation: $settings;
                -webkit-animation: $settings;
                -o-animation: $settings;
                -ms-animation: $settings;
                animation: $settings;
            }
        
            @mixin transform ($settings) {
                transform: $settings;
                -moz-transform: $settings;
                -webkit-transform: $settings;
                -o-transform: $settings;
                -ms-transform: $settings;
            }
        
            .pyro > .before, .pyro > .after {
                left: 10%;
                top: 30%;
                z-index: 100;
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                box-shadow: $box-shadow2;
                @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
            }
                    
            .pyro > .after {
                @include animation-delay((1.25s, 1.25s, 1.25s));
                @include animation-duration((1.25s, 1.25s, 6.25s));
            }
                        
            @include keyframes(bang) {
                to {
                    box-shadow:$box-shadow;
                }
            }
                    
                @include keyframes(gravity)  {
                to {
                    @include transform(translateY(200px));
                    opacity: 0;
                }
            }
                    
            @include keyframes(position) {
                0%, 19.9% {
                    margin-top: 10%;
                    margin-left: 40%;
                }
                20%, 39.9% {
                    margin-top: 40%;
                    margin-left: 30%;
                }
                40%, 59.9% {  
                    margin-top: 20%;
                    margin-left: 70%
                }
                60%, 79.9% {  
                    margin-top: 30%;
                    margin-left: 20%;
                }
                80%, 99.9% {  
                    margin-top: 30%;
                    margin-left: 80%;
                }
            }
    
        }
    }

    .tutorial-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 17%;

        .line {
            margin-bottom: 5%;
            width: 100%;
            border: none;
            border-top: 2vw dashed white;
        }

        .left {
            margin-bottom: 5%;
            @include nav-button-tutorial();
            left: 8%;
            width: 13%;
            & span {
                font-size: 2.8vw;
                margin-top: 180%;
            }    
        }

        .center {
            margin-bottom: 5%;
            @include nav-button-tutorial();
            width: 18%;
            & span {
                font-size: 4vw;
            }    
        }

        .right {
            margin-bottom: 5%;
            @include nav-button-tutorial();
            width: 13%;
            right: 8%;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
            animation: pulse 1s infinite;

            &.visible {
                opacity: 1;
            }

            & span {
                font-size: 2.8vw;
                margin-top: 180%;
            } 
        }

    }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.10);
    }
    100% {
      transform: scale(1);
    }
  }