
@import '../../../variables';

.research-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 13vw;
    margin-top: 1%;

    .research-back-button { 
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        width: 20%;
        height: 60%;
        cursor: pointer;
        z-index: 15;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        & img {
            height: 90%;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .research-title { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 100%;
        color: $white-100;
        font-size: 4vw;
        font-weight: 700;
        margin-right: 20%;
    }
}

.research-switch {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    min-height: 11vw;
    background-color: $light-color-100;
    margin-top: 1%;
    border-radius: 2vw;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

    .research-switch-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue-color-100;
        border: none;
        border-radius: 2vw;
        height: 75%;
        width: 30%;
        color: white;
        font-size: 3.3vw;
        font-weight: 500;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue;
            }
        }

        &.active {
            background-color: $dark-color-100;
            color: white;
        }
    }
}

.research-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;

    .create-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 4%;
    
        .create-img {
            width: 100%;
            border-radius: 3vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .create-info {
            position: absolute;
            align-items: center;
            justify-content: center;
            margin-bottom: 41%;
            margin-left: 80%;
            width: 6.5vw;
            aspect-ratio: 1/1;
            border: none;
            background-color: #E79A28;
            border-radius: 1vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                } 
            }
        }

        .research-portal {
            position: absolute;
            width: 60vw;
            height: 60vw;
            margin-left: 1.5%;
            display: flex;
            justify-content: center;
            align-items: center;
        
            .portal-core {
                width: 30vw;
                height: 30vw;
                background: radial-gradient(circle, #00ffcc, #0099cc);
                border-radius: 50%;
                box-shadow: 0 0 20px #00ffcc, 0 0 40px #0099cc, 0 0 60px #0066cc;
                animation: pulse 2s infinite;
            }
          
            .portal-glow {
                position: absolute;
                width: 100%;
                height: 100%;
                background: radial-gradient(circle, rgba(0, 255, 204, 0.5), rgba(0, 153, 204, 0.5));
                border-radius: 50%;
                filter: blur(20px);
                animation: glow 2s infinite;
            }
          
            .portal-light {
                position: absolute;
                width: 100%;
                height: 100%;
                background: radial-gradient(circle, rgba(0, 255, 204, 0.2), rgba(0, 153, 204, 0.2));
                border-radius: 50%;
                filter: blur(40px);
                animation: light 4s infinite;
            }
        }

        .recipe-box {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 51vw;
            margin-left: 1.5%;

            .center {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-color-100;
                width: 16%;
                aspect-ratio: 1/1;
                border-radius: 2vw;
                color: $dark-color-100;
                font-weight: 700;
                font-size: 9vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .component {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 13%;
                aspect-ratio: 1/1;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                & img {
                    height: 75%;
                }

                & span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 25%;
                    font-size: 3vw;
                    color: white;
                }

                &:nth-child(2) {
                    margin-bottom: 34vw; 
                    margin-right: 25%;
                }
                &:nth-child(3) {
                    margin-bottom: 34vw;  
                    margin-left: 25%;
                }
                &:nth-child(4) {
                    margin-top: 7vw; 
                    margin-right: 42%;
                }
                &:nth-child(5) {
                    margin-top: 7vw;  
                    margin-left: 42%;
                }
                &:nth-child(6) {
                    margin-top: 35vw; 
                }
            }
        }
    }

    .create-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 10vw;
        margin-top: 6%;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        border: none;
        background-color: $blue-color-100;
        padding: 0;
        color: white;
        border-radius: 2vw;
        font-size: 3.5vw;

        & span {
            margin-left: 5%;
        }

        & img {
            height: 90%;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue;
            }
        }

        &:active {
            transform: scale(0.95);
        }
    }
}

.soon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 87vw;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 13%;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes glow {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes light {
    0% {
        opacity: 0.2;
        transform: scale(1);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.2);
    }
    100% {
        opacity: 0.2;
        transform: scale(1);
    }
} 
