
$dark-color-90: rgba(17, 45, 78, 0.9);
$dark-color-100: rgba(17, 45, 78, 1);
$blue-color-90: rgba(63, 114, 175, 0.9);
$blue-color-100: rgba(63, 114, 175, 1);
$light-color-90: rgba(219, 226, 239, 0.9);
$light-color-100: rgba(219, 226, 239, 1);
$lightest-color-90: rgba(219, 226, 239, 0.9);
$lightest-color-100: rgba(219, 226, 239, 1);
$white-90: rgba(255, 255, 255, 0.9);
$white-100: rgba(255, 255, 255, 1);
$hover-light: rgb(193, 203, 223);
$hover-blue: rgb(42, 78, 122);
$hover-dark: rgb(22, 58, 99);
$orange: #E79A28;
$hover-orange: #af741a;

@mixin title {
    position: absolute;
    display: flex;
    color: $white-100;
    font-size: 7vw;
    font-weight: 900;
    left: 50%;
    top: 2%;
    transform: translateX(-50%);
    white-space: nowrap;
}

@mixin close-button {
    position: absolute;
    display: flex;
    background-color: $white-100;
    border-radius: 1.4vw;
    border: none;
    color: $dark-color-100;
    font-size: 5vw;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 6vw;
    cursor: pointer;

    &:active {
        transform: scale(0.95);
    }
}

@mixin back-button {
    position: absolute;
    display: flex;
    background-color: $white-100;
    border-radius: 1.4vw;
    border: none;
    align-items: center;
    justify-content: center;
    width: 14vw;
    height: 7vw;
    cursor: pointer;
    z-index: 15;

    &:active {
        transform: scale(0.95);
    }

    & img {
        height: 90%;
    }
    &:hover {
        background-color: $hover-light;
    }
}

.animate-success {
    animation: shadow-drop-center 0.7s linear both;
}

@keyframes shadow-drop-center {
    0% {
        box-shadow: 0 0 0 0 transparent;
    }
    100% {
        box-shadow: 0 0 10vw 0 rgba(255, 230, 0, 0.95);
    }
}

.jello-vertical { 
    animation:jello-vertical 0.9s linear both
}

@keyframes jello-vertical { 
    0% { 
        transform:scale3d(1,1,1) translateY(-50%)
    }
    30% { 
        transform:scale3d(.75,1.25,1) translateY(-50%)
    } 
    40% {
        transform:scale3d(1.25,.75,1) translateY(-50%)
    }
    50% {
        transform:scale3d(.85,1.15,1) translateY(-50%)
    }
    65% {
        transform:scale3d(1.05,.95,1) translateY(-50%)
    }
    75% {
        transform:scale3d(.95,1.05,1) translateY(-50%)
    }
    100% {
        transform:scale3d(1,1,1) translateY(-50%)
    }
}

@mixin load-animation {
    $offset: 187;
    $duration: 1.4s;

    .spinner {
        animation: rotator $duration linear infinite;
    }

    @keyframes rotator {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(270deg); }
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
            dash $duration ease-in-out infinite, 
            colors ($duration*4) ease-in-out infinite;
    }

    @keyframes colors {
        0% { stroke: #4285F4; }
        25% { stroke: #DE3E35; }
        50% { stroke: #F7C223; }
        75% { stroke: #1B9A59; }
        100% { stroke: #4285F4; }
    }

    @keyframes dash {
        0% { 
            stroke-dashoffset: $offset; }
        50% {
            stroke-dashoffset: $offset/4;
            transform:rotate(135deg);
        }
        100% {
            stroke-dashoffset: $offset;
            transform:rotate(450deg);
        }
    }
}

// Фейерверк
$particles: 60;
$width: 500;
$height: 400;
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
$box-shadow: $box-shadow,
            random($width)-$width / 2 + px
            random($height)-$height / 1.2 + px
            hsl(random(360), 100, 50);
$box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}

.pyro > .before, .pyro > .after {
    left: 25%;
    z-index: 100;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    box-shadow: $box-shadow2;
    @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
  }
      
  .pyro > .after {
    @include animation-delay((1.25s, 1.25s, 1.25s));
    @include animation-duration((1.25s, 1.25s, 6.25s));
  }
          
  @include keyframes(bang) {
    to {
      box-shadow:$box-shadow;
    }
  }
      
  @include keyframes(gravity)  {
    to {
      @include transform(translateY(200px));
      opacity: 0;
    }
  }
      
  @include keyframes(position) {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {  
      margin-top: 20%;
      margin-left: 70%
    }
    60%, 79.9% {  
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {  
      margin-top: 30%;
      margin-left: 80%;
    }
}