
@import '../../../variables';

.upgrade-store-overlayy {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 110vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;

            & span {
                color: $dark-color-100;
                font-size: 5vw;
                font-weight: 700;
            }
            .title-lvl {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70%;
                aspect-ratio: 1/1;
                background-color: $light-color-100;
                margin-left: 4%;
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 2.5vw;
                font-weight: 700;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }
        }

        .info {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 10vw;
            margin-top: 1.2vw;
            height: 7vw;
            aspect-ratio: 1/1;
            border: none;
            background-color: #E79A28;
            border-radius: 1vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                }
            }
        }  

        .lvl-boxes {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            height: 64%;
            margin-top: 12%;

            .lvl-box {
                flex-direction: column;
                display: flex;
                width: 46%;
                height: 100%;
                background-color: $light-color-100;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .capacity-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 10%;

                    .capacity {
                        color: $dark-color-100;
                        font-size: 2.5vw;
                        font-weight: 700;
                        margin-top: 5%;
                    }
                    .lvl {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 90%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;
                        margin-left: 3%;
                        border-radius: 1.5vw;
                        color: white;
                        font-size: 2vw;
                        font-weight: 700;
                        margin-top: 5%;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }

                .elements {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                    height: 90%;
                    border-radius: 2vw;
                    overflow-y: auto;
                    margin-top: 3%;
                    padding-left: 4%;
                    padding-bottom: 2%;

                    .element {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 10vw;
                        aspect-ratio: 1/1.2;
                        background-color: white;
                        border: solid $dark-color-100 2px;
                        box-sizing: border-box;
                        border-radius: 2vw;
                        margin: 2%;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                        .image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 70%;
    
                            & img {
                                height: 100%;
                            }
                        }
                        
                        & span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 30%;
                            color: white;
                            background-color: $blue-color-90;
                            border-radius: 0 0 1.35vw 1.35vw;
                            border-top: solid $dark-color-100 2px;
                            font-size: 2.6vw;
                        }
                    }
                } 
            }
        }

        .upgrade-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 21%;
            margin-top: 4%;
            
            .resources-box {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-evenly;
                width: 48%;
                height: 85%;

                .element {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43%;
                    height: 43%;

                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        aspect-ratio: 1/1;
                        background-color: rgba(63, 114, 175, 0.85);
                        border-radius: 1.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        & img {
                            height: 100%;
                        }
                    }
                    .amount {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50%;
                        height: 70%;
                        border-radius: 0 1.2vw 1.2vw 0;
                        background-color: $light-color-100;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                        color: $dark-color-100;
                        font-size: 3vw;
                    }
                }
            }

            .upgrade-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                height: 40%;
                margin-left: 4%;
                border: none;
                padding: 0;
                cursor: pointer;
                background-color: $blue-color-100;
                border-radius: 2vw;
                color: white;
                font-size: 3.2vw;
                font-weight: 700;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    } 
                }
                &.disabled {
                    opacity: 0.7;
                    pointer-events: none;
                }

                .loading-animation{ 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 300%;
                    width: 100%;
                    background: none;
                    margin-top: 75%;
                }
            }
        }
    }
}
