
@import '../../variables';

.buy-modal-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 42vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
        }

        .modal-message {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            height: 32%;
            margin-top: 10%;

            .modal-message-answer {
                display: flex;
                white-space: nowrap;
                color: $dark-color-100;
                font-size: 3.8vw;
            }

            .modal-message-value {
                font-weight: 500;
            }

            .modal-message-value-price {
                display: flex;
                align-items: center;
                white-space: nowrap;
                color: $dark-color-100;
                font-size: 3.8vw;
                margin-left: -5%;

                & img {
                    height: 8vw;
                }

                & span {
                    margin-left: 4%;
                }
            }

            .modal-message-alert {
                margin: 1% 0;
                font-size: 2.5vw;
                color: $dark-color-100;
            }
        }

        .modal-buttons {
            align-items: center;
            justify-content: space-evenly;
            display: flex;
            width: 100%;
            height: 25%;
            margin-top: 7.5%;

            .modal-button-yes {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.8vw;
                width: 35%;
                height: 100%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.loading {
                    pointer-events: none;
                }
                
                .loading-animation-buy { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    margin-top: 10%;
                    background: none;
                }
            }
            .modal-button-no {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.8vw;
                width: 35%;
                height: 100%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.loading-no {
                    opacity: 0.6;
                    pointer-events: none;
                }
                
                .loading-animation-buy { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    margin-top: 10%;
                    background: none;
                }
            }
        }
    }
}
