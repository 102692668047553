
@import '../../variables';

.select-modal-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96%;
            height: 70%;
            margin-top: 7%;

            .item-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 100%;

                .img-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    aspect-ratio: 1/1.2;
                    background-color: $light-color-100;
                    border-radius: 3vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    & img {
                        width: 90%;
                    }

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 25%;
                        background-color: $blue-color-100;
                        border-radius: 0 0 3vw 3vw;
                        color: white;
                        font-size: 5vw;
                    }
                }
            }

            .item-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 100%;

                .sell-bar {
                    position: relative;
                    display: flex;
                    width: 96%;
                    height: 18%;
                    border-radius: 2vw;
                    background-color: $lightest-color-100;
                    text-align: center;
                    overflow: hidden;
                    align-items: center;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    .sell-bar-fill {
                        display: flex;
                        height: 100%;
                        border-radius: 1.5vw;
                        background-color: rgb(173,255,0);
                        transition: width 0.3s ease;
                    }
                    
                    .sell-bar-text {
                        position: absolute;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $dark-color-100;
                        font-weight: 700;
                        font-size: 3.5vw;
                    }
                }

                .sell-amount {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 16%;
                    margin-top: 5%;

                    .sell-minus {
                        position: relative;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
    
                    .sell-input {
                        position: relative;
                        width: 50%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $lightest-color-100;
                        border: none;
                        border-radius: 2vw;
                        color: $dark-color-100;
                        font-weight: 700;
                        font-size: 3.5vw;
                        text-align: center;
                        margin-left: 4%;
                        margin-right: 4%;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    .sell-plus {
                        position: relative;
                        height: 100%;
                        aspect-ratio: 1/1;
                        cursor: pointer;
                        border: none;
                        padding: 0;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: 100%;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
                }

                .coins-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10%;
                    background-color: $light-color-100;
                    width: 96%;
                    height: 26%;
                    border-radius: 2vw;
                    color: $dark-color-100;
                    font-size: 5vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                    & img {
                        height: 75%;
                        margin-left: 3%;
                    }
                }
            }

            // Фейерверк
            $particles: 60;
            $width: 500;
            $height: 400;
            $box-shadow: ();
            $box-shadow2: ();
            @for $i from 0 through $particles {
            $box-shadow: $box-shadow,
                        random($width)-$width / 2 + px
                        random($height)-$height / 1.2 + px
                        hsl(random(360), 100, 50);
            $box-shadow2: $box-shadow2, 0 0 #fff
            }
            @mixin keyframes ($animationName) {
                @-webkit-keyframes #{$animationName} {
                    @content;
                }
        
                @-moz-keyframes #{$animationName} {
                    @content;
                }
        
                @-o-keyframes #{$animationName} {
                    @content;
                }
        
                @-ms-keyframes #{$animationName} {
                    @content;
                }
        
                @keyframes #{$animationName} {
                    @content;
                }
            }
        
            @mixin animation-delay ($settings) {
                -moz-animation-delay: $settings;
                -webkit-animation-delay: $settings;
                -o-animation-delay: $settings;
                -ms-animation-delay: $settings;
                animation-delay: $settings;
            }
        
            @mixin animation-duration ($settings) {
                -moz-animation-duration: $settings;
                -webkit-animation-duration: $settings;
                -o-animation-duration: $settings;
                -ms-animation-duration: $settings;
                animation-duration: $settings;
            }
        
            @mixin animation ($settings) {
                -moz-animation: $settings;
                -webkit-animation: $settings;
                -o-animation: $settings;
                -ms-animation: $settings;
                animation: $settings;
            }
        
            @mixin transform ($settings) {
                transform: $settings;
                -moz-transform: $settings;
                -webkit-transform: $settings;
                -o-transform: $settings;
                -ms-transform: $settings;
            }
        
            .pyro > .before, .pyro > .after {
                left: 10%;
                z-index: 100;
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                box-shadow: $box-shadow2;
                @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
            }
                    
            .pyro > .after {
                @include animation-delay((1.25s, 1.25s, 1.25s));
                @include animation-duration((1.25s, 1.25s, 6.25s));
            }
                        
            @include keyframes(bang) {
                to {
                    box-shadow:$box-shadow;
                }
            }
                    
                @include keyframes(gravity)  {
                to {
                    @include transform(translateY(200px));
                    opacity: 0;
                }
            }
                    
            @include keyframes(position) {
                0%, 19.9% {
                    margin-top: 10%;
                    margin-left: 40%;
                }
                20%, 39.9% {
                    margin-top: 40%;
                    margin-left: 30%;
                }
                40%, 59.9% {  
                    margin-top: 20%;
                    margin-left: 70%
                }
                60%, 79.9% {  
                    margin-top: 30%;
                    margin-left: 20%;
                }
                80%, 99.9% {  
                    margin-top: 30%;
                    margin-left: 80%;
                }
            }
        }

        .coins-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 15%;
            border: none;
            padding: 0;
            background-color: $blue-color-100;
            border-radius: 2vw;
            color: white;
            font-size: 4vw;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &:active {
                transform: scale(0.95);
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue; 
                }
            }

            &.disabled {
                opacity: 0.7;
                pointer-events: none;
            }

            .loading-animation { 
                @include load-animation;
                padding: 0;
                height: 100%;
                width: 100%;
                margin-top: 5%;
                background: none;
            }
        }

    }
}
