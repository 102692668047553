
@import '../../variables';

.settings-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 96vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
        }

        .language-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10%;
            width: 80%;
            height: 28%;

            .language-title {
                font-size: 4vw;
                color: $dark-color-100;
                margin-top: 3%;
                margin-bottom: 2%;
                font-weight: 700;
            }

            .overlay {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90%;
                height: 70%;

                .langImage-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $blue-color-100;
                    border-radius: 2.5vw;
                    height: 85%;
                    width: auto;
                    border: solid $dark-color-100 2px;

                    & img {
                        height: 100%;
                    }
                }

                .language-switch-box {
                    background-color: $blue-color-100;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    height: 47%;
                    margin-left: 7%;
                    border-radius: 1.5vw;
                    border: none;
                    cursor: pointer;
                    color: white;
                    font-size: 2.6vw;
                    z-index: 3;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue; 
                        }
                    }
    
                    .button-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        & span {
                            text-align: center;
                            width: 70%;
                        }
                        & img {
                            width: 18%;
                        }
                    }
    
                    &.swap {
                        margin-top: 42.7%;
                        height: auto;
                        //cursor: default;
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $blue-color-100; 
                            }
                        }
    
                        .button-content {
                            background-color: $dark-color-100;
                            height: 9vw;
                            border-radius: 2vw 2vw 0 0;
                        }
    
                        .options-box {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 26vw;
    
                            & button {
                                border: none;
                                background: none;
                                padding: 0;
                                color: white;
                                cursor: pointer;
                                width: 100%;
                                height: 35%;
                                font-size: 2.8vw;
    
                                @media (hover: hover) and (pointer: fine) {
                                    &:hover {
                                        background-color: $hover-blue; 
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }

        /* .language-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin-top: 10%;

            .lang {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 4vw;
                background-color: $blue-color-100;
                width: 35%;
                aspect-ratio: 1/0.8;
                margin: 5%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;
    
                & img {
                    height: 100%;
                }
    
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
    
                &.clicked {
                    pointer-events: none;
                    width: 40%;
                }
    
                &.no-clicked {
                    width: 30%;
                    opacity: 0.7;
                }
            }
        } */

        .notice-box {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .notice-title {
                font-size: 4vw;
                color: $dark-color-100;
                margin-top: 5%;
                margin-bottom: 2%;
                font-weight: 700;
            }
            
            .notice {
                display: flex;
                align-items: center;
                background-color: $light-color-100;
                width: 90%;
                height: 9vw;
                margin: 1.5% 0;
                border-radius: 1.5vw;
                font-size: 3.5vw;
                color: $dark-color-100;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);

                & span {
                    margin-left: 3%;
                }

                .switch-box {
                    position: absolute;
                    border: none;
                    padding: 0;
                    height: 6.5vw;
                    width: 14%;
                    border-radius: 2vw;
                    background-color: $blue-color-100;
                    right: 17%;
                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);
                    cursor: pointer;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }

                    .switch-element {
                        display: flex;
                        align-items: center;
                        height: 75%;
                        width: 45%;
                        background-color: $orange;
                        border-radius: 1.5vw;
                        transition: transform 0.3s ease;

                        &.true {
                            transform: translateX(100%);
                        }

                        &.false {
                            transform: translateX(20%);
                            background-color: $light-color-100;
                        }
                    }
                }
            }
        }
    }
}
