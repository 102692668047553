
@import '../../variables';

.clans-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: $dark-color-100;
    overflow-y: auto;
    box-shadow: inset 0px 8px 15px rgba(0, 0, 0, 0.40);
    z-index: 5;

    .clans-header {
        margin-top: 3%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 12vw;

        .clans-back-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 7.5%;
            width: 18%;
            height: 70%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            cursor: pointer;
            border: none;
            padding: 0;
            border-radius: 2vw;
            background-color: white;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 80%;
            }
        }

        .clans-title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 49%;
            margin-right: 25.5%;
            font-size: 4.5vw;
            color: white;
            font-weight: 700;
        }
    }
    
    .clans-info-box {
        background-color: $light-color-100;
        width: 85%;
        min-height: 105vw;
        margin-top: 3%;
        margin-bottom: 5%;
        border-radius: 3vw;
        display: flex;
        flex-direction: column;
        color: $dark-color-100;
        align-items: center;
        padding-top: 3%;
    
        & h1 {   
            font-size: 4.5vw;
            line-height: 0.05;
        }
    
        .clans-icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark-color-100;
            height: 27%;
            width: 45%;
            border-radius: 4vw;
            margin-top: 4%;
            margin-bottom: 5%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            & img {
                width: 80%;
            }
        }
    
        & span {
            width: 90%;
            font-size: 3vw;
            margin-bottom: 3%;
        }
    
        & li {
            width: 90%;
            font-size: 3vw;
            margin-bottom: 1%;
            margin-left: 10%;
        }
    
        .clans-buttons {
            display: flex;
            justify-content: center;
            margin-top: 3%;
            width: 85%;
            height: 10%;
    
            & button {
                border: none;
                background-color: $blue-color-100;
                border-radius: 3vw;
                height: 100%;
                width: 45%;
                color: white;
                font-size: 2.8vw;
                font-weight: 700;
                margin: 0 2%;
                //cursor: pointer;
                opacity: 0.75;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }
        }
    }
}