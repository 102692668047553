
@import '../../variables';

.modal-sold-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .modal-sold {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center; 
        
        .modal-sold-title {
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
            margin-top: 3%;
        }

        .modal-sold-lots {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 90%;
            height: 65%;
            background-color: $light-color-100;
            border-radius: 2vw;
            margin-top: 2%;
            padding-bottom: 2%;
            overflow-y: auto;

            .modal-sold-lot {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                height: 25%;
                background-color: $blue-color-100;
                border-radius: 2vw;
                margin-top: 2%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .modal-sold-lot-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    border-radius: 1.5vw;
                    height: 85%;
                    aspect-ratio: 1/1;

                    & img {
                        height: 100%;
                        aspect-ratio: 1/1;
                    }
                }

                .modal-sold-lot-amount {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-color-100;
                    border-radius: 0 1.5vw 1.5vw 0;
                    height: 62%;
                    width: 18%;
                    color: $dark-color-100;
                    font-size: 3vw;
                }

                .modal-sold-lot-price-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 3vw;
                    height: 65%;
                    margin-left: 3%;
                    text-wrap: nowrap;

                    & img {
                        height: 75%;
                        aspect-ratio: 1/1;
                        margin-left: 5%;
                    }
                }
                
            }
        }

        .modal-sold-ok {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-color-100;
            width: 30%;
            height: 13%;
            border: none;
            border-radius: 2vw;
            cursor: pointer;
            margin-top: 2.5%;
            color: white;
            font-size: 4vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
        }

    }
}