
@import '../../variables';

.airdrop-info-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 6vw;
            font-weight: 700;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 93%;
            margin-top: 7%;
            padding-bottom: 3%;

            .text1 {
                color: $dark-color-100;
                font-size: 5vw;
            }

            .text2 {
                color: $dark-color-100;
                font-size: 2.8vw;
                margin-top: 1%;
            }

            & img {
                height: 18%;
                margin: 1.5% 0;
            }

        }
    }
}
