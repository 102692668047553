
@import '../../../variables';

.mint-cont-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100vw;
    top: 0;
    background-color: $dark-color-100;
    z-index: 14;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;

    .mint-cont-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12vw;
        width: 90%;

        .mint-cont-back {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            width: 15%;
            height: 65%;
            left: 5vw;
            cursor: pointer;
            z-index: 15;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 90%;
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }

        .mint-cont-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            height: 100%;
            color: $white-100;
            font-size: 4.5vw;
            font-weight: 700;
            white-space: nowrap;
        }

        .collection-link-button {
            display: flex;
            align-items: center;
            justify-content: right;
            height: 100%;
            width: 15%;
            background: none;
            border: none;
            cursor: pointer;
            
            & img {
                height: 75%;
            }
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    transform: scale(0.95); 
                }
            }
        }
    }

    .mint-slider-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-color-100;
        width: 90%;
        height: 65vw;
        margin-top: 1%;
        border-radius: 3vw;
    
        .mint-slider {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
    
            .mint-cont-item {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 90%;
                width: 52%;
                border: none;
                background-color: $dark-color-100;
                border-radius: 2vw;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                &:nth-child(1) {
                    margin-left: -50%;
                    z-index: 11;
                }
                &:nth-child(2) {
                    z-index: 12;
                }
                &:nth-child(3) {
                    margin-left: 50%;
                    z-index: 11;
                }      
    
                & img {
                    height: 65%;
                    border-radius: 2vw;
                    margin-top: 5%;
                }

                .mint-cont-slider-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 30%;
                    width: 100%;
                    color: white;
                    font-size: 5.5vw;
                    font-weight: 700;

                    .mint-cont-slider-supply {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        left: 12%;
                        bottom: 33.5%;
                        background-color: white;
                        padding: 0 2vw;
                        height: 10%;
                        border-radius: 1.5vw;
                        color: $dark-color-100;
                        font-size: 2.7vw;
                        font-weight: 700;
                        opacity: 0;
                        transition: opacity 0.5s ease-in-out;

                        &.ready {
                            opacity: 1;
                        }
                    }

                    .mint-cont-slider-price {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 45%;

                        & img {
                            height: 80%;
                            margin-bottom: 4.5%;
                            margin-left: 2%;
                        }
                    }
                }

                .cashback {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 15%;
                    border-radius: 2vw;
                    background-color: $orange;
                    flex-direction: column;
                    color: rgb(255, 238, 0);
                    font-weight: 800;
                    transform: rotate(-30deg);
                    margin-top: 110%;
                    margin-left: 80%;
                    font-size: 2.5vw;
                    animation: pulse2 1.5s infinite;

                    .percent {
                        font-size: 4vw;
                    }

                    @keyframes pulse2 {
                        0% {
                            transform: rotate(-30deg) scale(1);
                        }
                        50% {
                            transform: rotate(-30deg) scale(0.9);
                        }
                        100% {
                            transform: rotate(-30deg) scale(1);
                        }
                    }
                }

                .sold-image {
                    position: fixed;
                    width: 25vw;
                    height: 25vw;
                    margin-top: 8%;
                }

            }
        }
    }

    .mint-cont-number-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
      
        button {
            position: relative;
            height: 12vw;
            cursor: pointer;
            border: none;
            padding: 0;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                height: 100%;
            }

            &:active {
                transform: scale(0.95);
            }
            &:first-child {
                margin-right: 3vw;
                & span {
                    margin-bottom: 20%;
                }
            }
          
            &:last-child {
                margin-left: 3vw; 
            }
        }
      
        .mint-cont-number-input {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 6vw;
            color: rgb(17, 45, 78);
            font-weight: 800;
            height: 12vw;
            width: 35vw;
            background-color: white;
            border: none;
            border-radius: 2vw;
            outline: none;
        }
    }
    
    .mint-cont-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        height: 12%;
        border: none;
        border-radius: 3vw;
        cursor: pointer;
        background-color: $blue-color-100;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        margin-top: 6%;
        color: white;
        font-weight: 700;
        font-size: 6.5vw;
        
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue; 
            }
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }
        &:not(.disabled):active {
            transform: scale(0.95);
        }
        & img {
            height: 45%;
            margin-left: 1%;
        }
    }

    .detention-conts {
        color: white;
        font-size: 3vw;
        margin-top: 2%;
    }
}