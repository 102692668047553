
@import '../../variables';

.default-get-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .default-get {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 75vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 30%;
        align-items: center;
    }

    .default-get-title {
        width: 100%;
        display: flex;
        justify-content: center;

        text-align: center;
        font-size: 4.5vw;
        width: 95%;
        font-weight: 700;
        color: $dark-color-100;
        margin-top: 3%;
    }

    .container {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        aspect-ratio: 1/1;
        margin-top: 3%;
        overflow: hidden;
        position: relative;
        border-radius: 4vw;
        overflow: hidden;

        .default-get-ship-name {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 5%;
            top: 5%;
            background-color: white;
            width: 50%;
            height: 14%;
            border-radius: 1.5vw;
            color: $dark-color-100;
            font-size: 3vw;
            font-weight: 700;
        }

        .default-get-ship-capacity {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 5%;
            top: 5%;
            background-color: white;
            width: 25%;
            height: 14%;
            border-radius: 1.5vw;
            color: $dark-color-100;
            font-size: 3vw;
            font-weight: 700;
            & img {
                height: 80%;
                margin-left: 3%;
            }
        }

        & img {
            height: 100%;
            object-fit: cover;
            transition: opacity 0.5s ease-in-out;
        }

        .question-mark {
            font-size: 40vw;
            background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
            background-size: 400% 400%;
            animation: gradient 10s ease infinite;
            transform: translate3d(0, 0, 0);
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 900;
        }

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
    

    .default-get-button {
        margin-top: 4%;
        border: none;
        background-color: $blue-color-100;
        border-radius: 2vw;
        color: white;
        font-weight: 900;
        font-size: 3.5vw;
        width: 40%;
        height: 13%;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        cursor: pointer;
        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue; 
            }
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.6;
        }

        .loading-animation { 
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%; 
            height: 90%; 
    
            @include load-animation;
        }
    } 

    // Фейерверк
    $particles: 60;
    $width: 500;
    $height: 400;
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
                random($width)-$width / 2 + px
                random($height)-$height / 1.2 + px
                hsl(random(360), 100, 50);
    $box-shadow2: $box-shadow2, 0 0 #fff
    }
    @mixin keyframes ($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @-ms-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @mixin animation-delay ($settings) {
        -moz-animation-delay: $settings;
        -webkit-animation-delay: $settings;
        -o-animation-delay: $settings;
        -ms-animation-delay: $settings;
        animation-delay: $settings;
    }

    @mixin animation-duration ($settings) {
        -moz-animation-duration: $settings;
        -webkit-animation-duration: $settings;
        -o-animation-duration: $settings;
        -ms-animation-duration: $settings;
        animation-duration: $settings;
    }

    @mixin animation ($settings) {
        -moz-animation: $settings;
        -webkit-animation: $settings;
        -o-animation: $settings;
        -ms-animation: $settings;
        animation: $settings;
    }

    @mixin transform ($settings) {
        transform: $settings;
        -moz-transform: $settings;
        -webkit-transform: $settings;
        -o-transform: $settings;
        -ms-transform: $settings;
    }

    .pyro > .before, .pyro > .after {
        left: 25%;
        z-index: 100;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        box-shadow: $box-shadow2;
        @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
      }
          
      .pyro > .after {
        @include animation-delay((1.25s, 1.25s, 1.25s));
        @include animation-duration((1.25s, 1.25s, 6.25s));
      }
              
      @include keyframes(bang) {
        to {
          box-shadow:$box-shadow;
        }
      }
          
      @include keyframes(gravity)  {
        to {
          @include transform(translateY(200px));
          opacity: 0;
        }
      }
          
      @include keyframes(position) {
        0%, 19.9% {
          margin-top: 10%;
          margin-left: 40%;
        }
        20%, 39.9% {
          margin-top: 40%;
          margin-left: 30%;
        }
        40%, 59.9% {  
          margin-top: 20%;
          margin-left: 70%
        }
        60%, 79.9% {  
          margin-top: 30%;
          margin-left: 20%;
        }
        80%, 99.9% {  
          margin-top: 30%;
          margin-left: 80%;
        }
    }
}
