
@import '../variables';

.skeleton {
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 4px;

    .skeleton-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(243, 243, 243, 1), rgba(210, 210, 210, 1), rgba(243, 243, 243, 1));
        animation: loading 1.5s infinite;
    }
      
    @keyframes loading {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
    }
}
