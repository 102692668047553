
@import '../../../variables';

.upgrade-cont-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 110vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .image {
            width: 50%;
            margin-top: 5%;
            border-radius: 3vw;
            margin-bottom: 2%;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }
            
        .info-button {
            position: absolute;
            height: 8vw;
            width: 8vw;
            right: 10%;
            margin-top: 4%;
            border: none;
            background-color: #E79A28;
            border-radius: 1vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                }
            }
        }  

        .lvl-box-next { 
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 10%;

            .lvl {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                aspect-ratio: 1/1;
                background-color: $light-color-100;
                border-radius: 2vw;
                color: $dark-color-100;
                font-size: 3.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .res {
                margin-left: 3%;
                color: $dark-color-100;
                font-size: 4.5vw;
            }
        }

        .lvl-box-current { 
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 10%;

            .lvl {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 85%;
                aspect-ratio: 1/1;
                background-color: $light-color-100;
                border-radius: 2vw;
                color: $dark-color-100;
                font-size: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }

            .res {
                margin-left: 3%;
                color: $dark-color-100;
                font-size: 3.5vw;
            }
        }

        .up-image {
            height: 10%;
        }

        .upgrade-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 21%;
            background-color: $light-color-100;
            margin-top: 3%;
            border-radius: 3vw;

            .elements {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 60%;

                .element {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 44%;
                    height: 40%;
                    margin: 0 2%;

                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2vw;
                        height: 100%;
                        aspect-ratio: 1/1;
                        background-color: $blue-color-100;

                        & img {
                            height: 100%;
                        }
                    }
                    

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50%;
                        height: 65%;
                        margin-top: 2.5%;
                        background-color: white;
                        border-radius: 0 1.5vw 1.5vw 0;
                        color: $dark-color-100;
                        font-size: 3.3vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }
            }

            .upgrade-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                height: 40%;
                border: none;
                padding: 0;
                border-radius: 2vw;
                background-color: $blue-color-100;
                cursor: pointer;
                color: white;
                font-size: 3.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
                &:active {
                    transform: scale(0.95);
                }
                &.disabled {
                    pointer-events: none;
                }

                .loading-animation{ 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 300%;
                    width: 100%;
                    background: none;
                    margin-top: 83%;
                }
            }
        }

        // Фейерверк
        $particles: 60;
        $width: 500;
        $height: 400;
        $box-shadow: ();
        $box-shadow2: ();
        @for $i from 0 through $particles {
        $box-shadow: $box-shadow,
                    random($width)-$width / 2 + px
                    random($height)-$height / 1.2 + px
                    hsl(random(360), 100, 50);
        $box-shadow2: $box-shadow2, 0 0 #fff
        }
        @mixin keyframes ($animationName) {
            @-webkit-keyframes #{$animationName} {
                @content;
            }
    
            @-moz-keyframes #{$animationName} {
                @content;
            }
    
            @-o-keyframes #{$animationName} {
                @content;
            }
    
            @-ms-keyframes #{$animationName} {
                @content;
            }
    
            @keyframes #{$animationName} {
                @content;
            }
        }
    
        @mixin animation-delay ($settings) {
            -moz-animation-delay: $settings;
            -webkit-animation-delay: $settings;
            -o-animation-delay: $settings;
            -ms-animation-delay: $settings;
            animation-delay: $settings;
        }
    
        @mixin animation-duration ($settings) {
            -moz-animation-duration: $settings;
            -webkit-animation-duration: $settings;
            -o-animation-duration: $settings;
            -ms-animation-duration: $settings;
            animation-duration: $settings;
        }
    
        @mixin animation ($settings) {
            -moz-animation: $settings;
            -webkit-animation: $settings;
            -o-animation: $settings;
            -ms-animation: $settings;
            animation: $settings;
        }
    
        @mixin transform ($settings) {
            transform: $settings;
            -moz-transform: $settings;
            -webkit-transform: $settings;
            -o-transform: $settings;
            -ms-transform: $settings;
        }
    
        .pyro > .before, .pyro > .after {
            left: 10%;
            top: 50%;
            z-index: 100;
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            box-shadow: $box-shadow2;
            @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
        }
                
        .pyro > .after {
            @include animation-delay((1.25s, 1.25s, 1.25s));
            @include animation-duration((1.25s, 1.25s, 6.25s));
        }
                    
        @include keyframes(bang) {
            to {
                box-shadow:$box-shadow;
            }
        }
                
            @include keyframes(gravity)  {
            to {
                @include transform(translateY(200px));
                opacity: 0;
            }
        }
                
        @include keyframes(position) {
            0%, 19.9% {
                margin-top: 10%;
                margin-left: 40%;
            }
            20%, 39.9% {
                margin-top: 40%;
                margin-left: 30%;
            }
            40%, 59.9% {  
                margin-top: 20%;
                margin-left: 70%
            }
            60%, 79.9% {  
                margin-top: 30%;
                margin-left: 20%;
            }
            80%, 99.9% {  
                margin-top: 30%;
                margin-left: 80%;
            }
        }

    }
}
