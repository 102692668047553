
@import '../../variables';

.end-voyage-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    z-index: 11;

    .end-voyage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85vw;
        height: 110vw;
        background-color: white;
        border-radius: 3vw;
        
        .end-voyage-title {
            display: flex;
            align-items: center; 
            justify-content: center;
            width: 100%;
            height: 10vw;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
        }

        .end-voyages-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 90%;
            height: 78%;
            overflow-y: auto;
            border: solid $dark-color-100 2px;
            border-radius: 2vw;
            padding-bottom: 1%;

            .voyage-box {
                display: flex;
                flex-direction: column;
                margin-left: 5vw;
                width: 95%;
                height: 35vw;
                background-color: $light-color-100;
                border-radius: 2vw;
                margin: 2% 0;
                flex-shrink: 0;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .voyage-info {
                    display: flex;
                    align-items: center; 
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    .country-left {
                        display: flex;
                        flex-direction: column;
                        align-items: center; 
                        justify-content: center;
                        height: 88%;
                        width: 35%;

                        .country-name {
                            display: flex;
                            align-items: center; 
                            justify-content: center;
                            width: 100%;
                            height: 15%;
                            font-size: 2.3vw;
                            color: $dark-color-100;
                            font-weight: 700;
                            background-color: white;
                            margin-bottom: 5%;
                            border-radius: 1vw;
                            text-wrap: nowrap;
                        }

                        .ship-box {
                            position: relative;
                            display: flex;
                            align-items: center; 
                            justify-content: center;        
                            height: 80%;
                            width: 92%;
    
                            & img {
                                height: 100%;
                                border-radius: 2vw;
                                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                            }
    
                            .name {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: rgba(255, 255, 255, 0.8);
                                border-radius: 1vw;
                                height: 14%;
                                width: 70%;
                                left: 5%;
                                top: 5%;
                                font-size: 2.2vw;
                                color: $dark-color-100;
                                text-wrap: nowrap;
                            }
            
                            .lvl {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: rgba(255, 255, 255, 0.8);
                                border-radius: 1vw;
                                height: 14%;
                                width: 32%;
                                right: 5%;
                                top: 81%;
                                font-size: 2.2vw;
                                color: $dark-color-100;
                                text-wrap: nowrap;
                            }
    
                            .capacity {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: rgba(255, 255, 255, 0.8);
                                border-radius: 1vw;
                                height: 14%;
                                width: 34%;
                                left: 5%;
                                top: 81%;
                                font-size: 2.7vw;
                                color: $dark-color-100;
                                text-wrap: nowrap;
                        
                                & img {
                                    box-shadow: none;
                                    margin-left: 5%;
                                    height: 90%;
                                    width: auto;
                                }
                            }
                        }
                    }

                    .conts-res-info {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center; 
                        justify-content: center;
                        width: 57%;
                        height: 88%;
                        margin-left: 4%;

                        .conts-box {
                            position: relative;
                            display: flex;
                            align-items: center; 
                            justify-content: center;
                            width: 100%;
                            height: 42%;
                            margin-bottom: 3%;
                            border-radius: 1.5vw;
                            background-color: white;
                            flex-wrap: wrap;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                            .cont-item {
                                display: flex;
                                align-items: center; 
                                justify-content: center;
                                width: 28%;
                                height: 45%;
                                margin: 0 1.5%;

                                .cont-image {
                                    display: flex;
                                    align-items: center; 
                                    justify-content: center;
                                    width: 48%;

                                    &.silver {
                                        border-radius: 1vw;
                                        border: solid $dark-color-100 1px;
                                    }

                                    & img {
                                        border-radius: 1vw;
                                        width: 100%;
                                        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);
                                    }
                                }

                                .amount {
                                    display: flex;
                                    align-items: center; 
                                    justify-content: center;
                                    width: 50%;
                                    height: 68%;
                                    border-radius: 0 0.8vw 0.8vw 0;
                                    background-color: $light-color-100;
                                    color: $dark-color-100;
                                    font-size: 2.8vw;
                                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);
                                }
                            }
                        }

                        .receive-box {
                            position: relative;
                            display: flex;
                            align-items: center; 
                            justify-content: center;
                            width: 100%;
                            height: 55%;
                            border-radius: 1.5vw;
                            background-color: $blue-color-100;
                            flex-wrap: wrap;
                            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                            .resource-item {
                                display: flex;
                                align-items: center; 
                                justify-content: center;
                                width: 30%;
                                height: 45%;
                                margin: 0 1.5%;

                                .resource-img-box {
                                    display: flex;
                                    align-items: center; 
                                    justify-content: center;
                                    height: 75%;
                                    width: 45%;
                                    border-radius: 1vw;
                                    background-color: white;
                                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);

                                    & img {
                                        width: 85%;
                                    }
                                }

                                .resource-amount {
                                    display: flex;
                                    align-items: center; 
                                    justify-content: center;
                                    width: 50%;
                                    height: 56%;
                                    border-radius: 0 1vw 1vw 0;
                                    background-color: $light-color-100;
                                    color: $dark-color-100;
                                    font-size: 2.5vw;
                                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.30);
                                }
                            }
                        }
                    }

                }
            }
        }

        .modal-end-voyage-ok {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-color-100;
            margin-top: 2.5%;
            width: 30%;
            height: 7.5%;
            border: none;
            border-radius: 2vw;
            cursor: pointer;
            color: white;
            font-size: 4vw;
            font-weight: 900;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &.disabled {
                pointer-events: none;
            }

            .loading-animation{ 
                @include load-animation;
                margin: 0;
                padding: 0;
                height: 90%;
                width: 100%;
            }
        }

    }
}