
@import '../variables';

.alert-box {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 15vw;
    top: 2%;
    width: 70vw;
    height: 7%;
    border-radius: 3vw;
    border: none;
    background-color: rgba(63, 114, 175, 0.95);
    z-index: 1000;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    transition: opacity 0.3s ease-in-out;

    &.fade-in {
        opacity: 1;
    }
    
    &.fade-out {
        opacity: 0;
    }

    & img {
        width: 12%;
        margin: 0 3%;
    }

    & span {
        color: white;
        font-size: 3vw;
        font-weight: 700;
    }
}