
@import '../../../variables';

.claim-rig-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 70vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;
        justify-content: center;

        .x2 {
            width: 45%;
        }

        .claim-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $dark-color-100;

            .title {
                font-size: 6vw;
                font-weight: 700;
                margin-top: 2%;
            }

            .info {
                margin-top: 2%;
                text-align: center;
                font-size: 3.5vw;
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 28%;

            .claim {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 57%;
                border: none;
                padding: 0;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 4.5vw;
                cursor: pointer;

                .part1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: 100%;

                    & img {
                        height: 65%;
                    }
                }

                .part2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    height: 100%;
                    margin-right: 10%;

                    & img {
                        height: 80%;
                    }
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.ton {
                    background-color: $orange;
                    
                    .loading-claim { 
                        @include load-animation;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        background: none;
                        margin-top: 11%;
                    }

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-orange;
                        }
                    }
                }

                & img {
                    height: 80%;
                }

                &:active {
                    transform: scale(0.95);
                }

                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }

                &.disabled-ton {
                    pointer-events: none;
                }

                .loading-claim { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    background: none;
                    margin-top: 11%;
                }
            }
        }
    }
}
