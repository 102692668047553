
@import '../../../variables';

.mint-ships-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100vw;
    background-color: $dark-color-100;
    overflow-y: auto;
    z-index: 14;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;

    .mint-ships-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12vw;
        width: 90%;

        .mint-ships-back {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            width: 15%;
            height: 65%;
            left: 5vw;
            cursor: pointer;
            z-index: 15;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 90%;
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }

        .mint-ships-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            height: 100%;
            color: $white-100;
            font-size: 4.5vw;
            font-weight: 700;
            white-space: nowrap;
        }

        .collection-link-button {
            display: flex;
            align-items: center;
            justify-content: right;
            height: 100%;
            width: 15%;
            background: none;
            border: none;
            cursor: pointer;
            
            & img {
                height: 75%;
            }
            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    transform: scale(0.95); 
                }
            }
        }

    }

    .mint-slider-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-color-100;
        width: 90%;
        height: 65vw;
        margin-top: 2%;
        border-radius: 3vw;
    
        .mint-slider {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
    
            .mint-ship-item {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 90%;
                width: 52%;
                border: none;
                background-color: $dark-color-100;
                border-radius: 2vw;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                &:nth-child(1) {
                    margin-left: -50%;
                    z-index: 11;
                }
                &:nth-child(2) {
                    z-index: 12;
                }
                &:nth-child(3) {
                    margin-left: 50%;
                    z-index: 11;
                }      
    
                & img {
                    height: 70%;
                    border-radius: 2vw;
                    margin-top: 5%;
                }
    
                .mint-ship-slider-name {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 10%;
                    top: 7%;
                    background-color: white;
                    width: 50%;
                    height: 11%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 3vw;
                    font-weight: 700;
                }

                .mint-ship-slider-supply {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 10%;
                    bottom: 29%;
                    background-color: white;
                    padding: 0 2vw;
                    height: 11%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 3vw;
                    font-weight: 700;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;

                    &.ready {
                        opacity: 1;
                    }
                }

                .mint-ship-capacity {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 10%;
                    top: 7%;
                    background-color: white;
                    width: 25%;
                    height: 11%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 3vw;
                    font-weight: 700;
                    & img {
                        height: 75%;
                        margin-left: 3%;
                    }
                }

                .mint-ship-slider-price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 25%;
                    width: 100%;
                    color: white;
                    font-size: 5.5vw;
                    font-weight: 700;

                    & img {
                        height: 45%;
                        margin-bottom: 4.5%;
                        margin-left: 2%;
                    }
                }

                .cashback {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 15%;
                    border-radius: 2vw;
                    background-color: $orange;
                    flex-direction: column;
                    color: rgb(255, 238, 0);
                    font-weight: 800;
                    transform: rotate(-30deg);
                    margin-top: 110%;
                    margin-left: 80%;
                    font-size: 2.5vw;
                    animation: pulse1 1.5s infinite;

                    .percent {
                        font-size: 4vw;
                    }

                    @keyframes pulse1 {
                        0% {
                            transform: rotate(-30deg) scale(1);
                        }
                        50% {
                            transform: rotate(-30deg) scale(0.9);
                        }
                        100% {
                            transform: rotate(-30deg) scale(1);
                        }
                    }
                }

                .sold-image {
                    position: fixed;
                    width: 25vw;
                    height: 25vw;

                    &.center {
                        margin-top: 11%;
                    }
                    &.side {
                        margin-top: 24%;
                    }
                    
                }
            }
        }
    }

    .mint-ship-number-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
      
        button {
            position: relative;
            height: 12vw;
            cursor: pointer;
            border: none;
            padding: 0;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                height: 100%;
            }

            &:active {
                transform: scale(0.95);
            }
            &:first-child {
                margin-right: 3vw;
                & span {
                    margin-bottom: 20%;
                }
            }
          
            &:last-child {
                margin-left: 3vw; 
            }
        }
      
        .mint-ship-number-input {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 6vw;
            color: rgb(17, 45, 78);
            font-weight: 800;
            height: 12vw;
            width: 35vw;
            background-color: white;
            border: none;
            border-radius: 2vw;
            outline: none;
        }
    }
    
    .mint-ship-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        height: 12%;
        border: none;
        border-radius: 3vw;
        cursor: pointer;
        background-color: $blue-color-100;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        margin-top: 6%;
        color: white;
        font-weight: 700;
        font-size: 6.5vw;

        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue; 
            }
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }
        &:not(.disabled):active {
            transform: scale(0.95);
        }
        & img {
            height: 45%;
            margin-left: 1%;
        }
        .loading-animation-minting {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70%; 

            @include load-animation;
        }
    }

    .detention-ships {
        color: white;
        font-size: 3vw;
        margin-top: 2%;
    }

    .loading-animation-load-supply-ship {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin-top: 15%; 

        @include load-animation;
    }
}