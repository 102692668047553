
@import '../../../variables';

.rig {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    margin-bottom: 4%;

    & img {
        width: 100%;
        border-radius: 3vw;
    }

    .rig-overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 3vw;

        .rig-header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 25%;

            .title {
                color: white;
                font-size: 5vw;
                font-weight: 500;
            }

            .info-overlay {
                margin-left: 2%;
                height: 65%;
                aspect-ratio: 1/1;
                border: none;
                background-color: #E79A28;
                border-radius: 1vw;
                color: $dark-color-100;
                font-size: 5vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: #af741a;
                    }
                }
            }
        }

        .rig-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 75%;

            .requirements {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 55%;
                height: 85%;
                border-radius: 2vw;
                background-color: rgba(255, 255, 255, 0.5);
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .requirement {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 42%;
                    height: 40%;
                    margin: 2%;

                    .img-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        aspect-ratio: 1/1;
                        background-color: white;
                        border-radius: 1.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                        & img {
                            height: 100%
                        }
                    }

                    .amount {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 54%;
                        height: 70%;
                        background-color: $light-color-100;
                        border-radius: 0vw 1.5vw 1.5vw 0;
                        color: $dark-color-100;
                        font-size: 3.5vw;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    }
                }
            }

            .build-button {
                margin-left: 3%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                height: 30%;
                background-color: $blue-color-100;
                border: none;
                padding: 0;
                cursor: pointer;
                border-radius: 2vw;
                color: white;
                font-size: 4vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.disabled {
                    pointer-events: none;
                }

                &:active {
                    transform: scale(0.95);
                }

                .loading-build { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    background: none;
                    margin-top: 7%;
                }
            }
        }
    }

    // Фейерверк
    $particles: 60;
    $width: 500;
    $height: 400;
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
                random($width)-$width / 2 + px
                random($height)-$height / 1.2 + px
                hsl(random(360), 100, 50);
    $box-shadow2: $box-shadow2, 0 0 #fff
    }
    @mixin keyframes ($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @-ms-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @mixin animation-delay ($settings) {
        -moz-animation-delay: $settings;
        -webkit-animation-delay: $settings;
        -o-animation-delay: $settings;
        -ms-animation-delay: $settings;
        animation-delay: $settings;
    }

    @mixin animation-duration ($settings) {
        -moz-animation-duration: $settings;
        -webkit-animation-duration: $settings;
        -o-animation-duration: $settings;
        -ms-animation-duration: $settings;
        animation-duration: $settings;
    }

    @mixin animation ($settings) {
        -moz-animation: $settings;
        -webkit-animation: $settings;
        -o-animation: $settings;
        -ms-animation: $settings;
        animation: $settings;
    }

    @mixin transform ($settings) {
        transform: $settings;
        -moz-transform: $settings;
        -webkit-transform: $settings;
        -o-transform: $settings;
        -ms-transform: $settings;
    }

    .pyro > .before, .pyro > .after {
        left: 10%;
        top: 50%;
        z-index: 100;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        box-shadow: $box-shadow2;
        @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
    }
            
    .pyro > .after {
        @include animation-delay((1.25s, 1.25s, 1.25s));
        @include animation-duration((1.25s, 1.25s, 6.25s));
    }
                
    @include keyframes(bang) {
        to {
            box-shadow:$box-shadow;
        }
    }
            
        @include keyframes(gravity)  {
        to {
            @include transform(translateY(200px));
            opacity: 0;
        }
    }
            
    @include keyframes(position) {
        0%, 19.9% {
            margin-top: 10%;
            margin-left: 40%;
        }
        20%, 39.9% {
            margin-top: 40%;
            margin-left: 30%;
        }
        40%, 59.9% {  
            margin-top: 20%;
            margin-left: 70%
        }
        60%, 79.9% {  
            margin-top: 30%;
            margin-left: 20%;
        }
        80%, 99.9% {  
            margin-top: 30%;
            margin-left: 80%;
        }
    }

    .rig-builded {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .name-info {
            position: absolute;
            display: flex;
            align-items: center;
            height: 22%;
            width: 55%;
            left: 2%;
            top: 4%;

            .name {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 1.5vw;
                color: $dark-color-100;
                font-size: 3vw;
                font-weight: 500;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            }
    
            .info-active {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5%;
                height: 80%;
                aspect-ratio: 1/1;
                border: none;
                background-color: #E79A28;
                border-radius: 1vw;
                color: $dark-color-100;
                font-size: 5vw;
                font-weight: 700;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: #af741a;
                    }
                }
            }
        }

        .techs {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width : 20%;
            height: 22%;
            margin-top: 29%;
            margin-left: 76%;

            .tech {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                aspect-ratio: 1/1;
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 1.5vw;
                border: none;
                padding: 0;
                cursor: pointer;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba(223, 223, 223, 0.75);
                    }
                }

                &:active {
                    transform: scale(0.95);
                }

                & img {
                    height: 75%;
                    width: 75%;
                }
            }
        }

        .upgrades {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width : 25%;
            height: 50%;
            margin-bottom: 17%;
            margin-left: 71%;

            .upgrade {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 46%;
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 1.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .info-up {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 65%;
                    height: 100%;

                    & img {
                        border-radius: 0;
                        width: 45%;
                        margin-right: 4%;
                    }
    
                    & span {
                        color: $dark-color-100;
                        font-size: 2.3vw;
    
                    }
                }
                
                .upgrade-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35%;
                    height: 100%;
                    background-color: $blue-color-100;
                    border-radius: 0 1.3vw 1.3vw 0;
                    cursor: pointer;
                    border: none;
                    padding: 0;

                    & img {
                        width: 75%;
                    }

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }
                }
            }
        }

        .farm-box {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42%;
            height: 60%;
            margin-top: 10.5%;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 1.5vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            .start-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                height: 45%;
                background-color: $blue-color-100;
                border-radius: 1.5vw;
                cursor: pointer;
                border: none;
                padding: 0;
                color: white;
                font-size: 3.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.disabled {
                    pointer-events: none;
                }

                &:active {
                    transform: scale(0.95);
                }

                .loading-start { 
                    @include load-animation;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    background: none;
                    margin-top: 10%;
                }
            }

            .farm-box-active {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .farm-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 25%;

                    .part1 {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        margin-left: 3%;
                        width: 52%;
                        height: 100%;
                        color: $dark-color-100;
                        font-size: 3vw;
                        font-weight: 700;
                    }

                    .part2 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 45%;
                        height: 100%;

                        & span {
                            color: $box-shadow;
                            font-size: 3vw;
                        }

                        & img {
                            width: 30%;
                        }
                    }
                }

                .farm-farming {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 25%;

                    & img {
                        width: 18%;
                        border-radius: 0;
                        margin-right: 2%;
                    }

                    .progress-bar {
                        position: relative;
                        display: flex;
                        width: 72%;
                        height: 80%;
                        border-radius: 1.8vw;
                        background-color: $lightest-color-100;
                        text-align: center;
                        overflow: hidden;
                        align-items: center;
                        border: solid 2px $dark-color-100;
                        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                        .progress-bar-fill {
                            display: flex;
                            height: 100%;
                            border-radius: 1.2vw;
                            background-color: rgb(173,255,0);
                            transition: width 0.3s ease;
                        }
                        
                        .progress-bar-text {
                            position: absolute;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $dark-color-100;
                            font-size: 3.4vw;
                            z-index: 5;
                        }

                        .min-line {
                            position: absolute;
                            height: 100%;
                            width: 30%;
                            border-right: solid 2px $orange;
                        }
                    }
                }

                .rig-claim-button {
                    margin-top: 4%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: none;
                    width: 60%;
                    height: 30%;
                    border-radius: 1.5vw;
                    background-color: $blue-color-100;
                    color: white;
                    font-size: 3.2vw;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                    opacity: 0.6;
                    pointer-events: none;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-blue;
                        }
                    }

                    &.active {
                        opacity: 1;
                        pointer-events: all;
                    }
    
                    &:active {
                        transform: scale(0.95);
                    }

                    &.loading {
                        opacity: 0.6;
                        pointer-events: none;
                    }
    
                    .loading-claim { 
                        @include load-animation;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        background: none;
                        margin-top: 11%;
                    }
                }
            }
        }
    }
}

.loading-animation{ 
    @include load-animation;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 36vw;
    border-radius: 3vw;
    margin-bottom: 4%;
}