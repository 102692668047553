@import '../../variables';

.tasks-page {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 3;
    background-color: $dark-color-100;
    box-shadow: 
        0px 6px 6px rgba(0, 0, 0, 0.30) inset,
        0px -6px 6px rgba(0, 0, 0, 0.30) inset;
    
    .loading-tasks-overlay {
        display: flex;
        justify-content: center;
        height: 83vh;
        width: 100%;

        .loading-tasks { 
            @include load-animation;
            margin: 0;
            padding: 0;
            height: 40vw;
            margin-top: 20%;
            background: none;
        }
    }

    .tasks-header {
        width: 100%;
        height: 12vw;
        display: flex;
        align-items: flex-end;

        .tasks-back {
            display: flex;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            align-items: center;
            justify-content: center;
            width: 18vw;
            height: 8vw;
            margin-left: 5vw;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 90%;
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }

        .tasks-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49vw;
            height: 8vw;
            color: white;
            font-weight: 700;
            font-size: 7vw;
        }
    }

    .task-list-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90vw;
        border: none;
        background-color: $light-color-100;
        border-radius: 2.5vw;
        margin: 5vw 0;
    
        .tasks-title {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 10vw;
            color: $dark-color-100;
            font-size: 4.5vw;
            font-weight: 700;
    
            .tasks-amount {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4vw;
                font-weight: 700;
                margin-left: 2%;
                color: $dark-color-100;
                background-color: $orange;
                height: 70%;
                aspect-ratio: 1/1;
                border-radius: 1.5vw;
            }
    
            &.completed {
                width: 100%;
                justify-content: start;
    
                .tasks-title-text {
                    margin-left: 28%;
                    width: 42%;
                }
    
                .tasks-amount {
                    width: 25%;
                    font-size: 3vw;
                    font-weight: 500;
                    background-color: white;
                }
            }
        }
        
        .task-list {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 3%;
            margin-top: -1.5%;
        
            .task-item {
                position: relative;
                display: flex;
                align-items: center;
                height: 15vw;
                width: 94%;
                margin-top: 2.5%;
                background-color: $blue-color-100;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        
                &.completed {     
                    .success-img {
                        position: absolute;
                        display: flex;
                        right: 0;
                    }
        
                }
        
                & img {
                    height: 65%;
                    border-radius: 100%;
                    margin-left: 1%;
                }
            
                .task-text-box {
                    display: flex;
                    flex-direction: column;
                    color: white;
                    font-size: 2.8vw;
                    width: 67%;
                    height: 100%;
                    margin-left: 1%;
    
                    .task-text {
                        display: flex;
                        align-items: center;
                        min-height: 40%;
    
                    }
                    
                    .reward-box {
                        display: flex;
                        height: 60%;
    
                        .reward-item {
                            display: flex;
                            align-items: center;
                            height: 85%;
                            width: 33%;
                            margin-right: 4%;
    
                            .reward-item-img-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 100%;
                                aspect-ratio: 1/1;
                                background-color: white;
                                border-radius: 1.5vw;
                                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                                & img {
                                    height: 85%;
                                }
                            }
        
                            .reward-item-amount-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $light-color-100;
                                border-radius: 0 1.5vw 1.5vw 0;
                                width: 45%;
                                height: 73%;
                                color: $dark-color-100;
                                font-size: 3vw;
                                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                            }
                        }
                    }
                }
            
                .task-button {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 0;
                    margin-right: 2%;
                    border: none;
                    width: 20%;
                    height: 50%;
                    background-color: $dark-color-100; 
                    border-radius: 1.5vw;
                    color: white;
                    font-size: 3vw;
                    cursor: pointer;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-dark; 
                        }
                    }
                    &.disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
    
                    .loading-animation-check {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%; 
                        height: 90%; 
    
                        @include load-animation;
                    }
                }
    
                .success-img {
                    margin-right: 5%;
                }
    
                &.premium {
                    background-color: $orange;
    
                    .task-text-box {
                        color: $dark-color-100;
                    }
    
                    .task-button {
                        background-color: $blue-color-100;
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: $hover-blue; 
                            }
                        }
                    }
                }
            }
        }
    }
}

