
@import '../../variables';


.content {
    display: flex;
    justify-content: center;
    height: 83%;
    width: 100vw;
    overflow-y: auto;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;

    &.fade-out {
        opacity: 0;
        transform: scale(0.95);
    }

    &.fade-in {
        opacity: 1;
        transform: scale(1);
    }
}

.navigation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 9%;
    width: 100vw;
    border: none;
    background-color: $dark-color-100;
    box-shadow: 0px -8px 15px rgba(0, 0, 0, 0.40);
    transition: opacity 0.12s ease-in-out;

    .nav-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
        height: 80%;
        border: none;
        background: none;
        border-radius: 2.5vw;
        cursor: pointer;
        outline: none;

        .nav-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 75%;
            aspect-ratio: 1/1;

            & img {
                width: 85%;
            }
        }

        .navigation-text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 25%;
            display: flex;
            color: white;
            font-size: 2.6vw;

            &.airdrop {
                font-weight: 700;
                color: $orange;
            }
        }

        &:active {
            transform: scale(0.95);

            .new-event {
                margin-bottom: 54%;
                margin-left: 54%;
            }
        }

        &.active {
            pointer-events: none;
            background-color: $blue-color-100;
        }

        &.active-map {
            pointer-events: all;
            background-color: $blue-color-100;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: rgb(42, 78, 122);
            }
        }

        .new-event {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #E10000;
            color: white;
            font-weight: 700;
            font-size: 2.2vw;
            width: 7vw;
            height: 3vw;
            border-radius: 1vw;
            font-family: "Trebuchet MS", sans-serif;
            margin-bottom: 8%;
            margin-left: 8%;
        }
    }
}

