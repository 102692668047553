
@import '../../variables';

.market-buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 81vh;
    overflow-y: auto;

    .market-buy-store {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        height: 60vw;
        margin-top: 4%;
    
        .buy-switch-box {
            display: flex;
            align-items: end;
            width: 100%;
            height: 15%;
    
            .switch-button1 {
                width: 50%;
                height: 100%;
                border: none;
                padding: 0;
                border-radius: 2vw 0 0 0;
                cursor: pointer;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                &.active {
                    box-shadow: 
                        0px 8px 8px rgba(0, 0, 0, 0.30) inset,
                        0px 8px 8px rgba(0, 0, 0, 0.30);
                    background-color: $dark-color-100;
                }
            }
    
            .switch-button2 {
                width: 50%;
                height: 100%;
                border: none;
                padding: 0;
                border-radius: 0 2vw 0 0;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                cursor: pointer;
                &.active {
                    box-shadow: 
                        0px 8px 8px rgba(0, 0, 0, 0.30) inset,
                        0px 8px 8px rgba(0, 0, 0, 0.30);
                    background-color: $dark-color-100;
                }
            }
        }
    
        .store {
            display: flex;
            align-items: start;
            justify-content: start;
            flex-wrap: wrap;
            width: 94%;
            height: 85%;
            background-color: $light-color-100;
            border-radius: 0 0 2vw 2vw;
            overflow-y: auto;
            padding-top: 3%;
            padding-left: 6%;
    
            .element-button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0;
                background-color: white;
                border: solid $dark-color-100 2px;
                height: 26%;
                aspect-ratio: 1/1;
                cursor: pointer;
                margin: 2%;
                border-radius: 1.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                & img {
                    height: 95%;
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgb(241, 247, 255);
                    }
                }
                &.selected {
                    background-color: $blue-color-100;
                    cursor: default;
                }
            }
        }
    }
    
    .buy-list-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 90%;
        background-color: $light-color-100;
        border-radius: 2vw;
        margin-top: 4%;
        margin-bottom: 4%;
        padding-bottom: 3%;

        .loading-animation{ 
            @include load-animation;
            margin-left: 0%;
            margin-top: 4%;
            padding: 0;
            height: 10vw;
            width: 100%;
            background: none;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 4%;
            width: 94%;
            height: 15vw; 
            border: none;

            .img-box {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white-100;
                width: 18%;
                aspect-ratio: 1/1;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                & img {
                    height: 95%;
                }

                .value-box {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    width: 65%;
                    height: 60%;
                    margin-right: 35%;
                    margin-top: 40%;
                    background-color: rgba(17, 45, 78, 0.7);
                    border-radius: 0 100% 0 2vw;
                    color: white;
                    font-size: 3.5vw;
                    font-weight: 500;
    
                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60%;
                        margin-left: 13%;
                        margin-top: 10%;
                    }
                }
            }

            .info-box {
                display: flex;
                align-items: center;
                justify-content: start;
                background-color: $blue-color-100;
                border-radius: 0 2vw 2vw 0;
                height: 75%;
                width: 82%;
                margin-top: 0;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                .seller-box {
                    display: flex;
                    flex-direction: column;
                    width: 27%;
                    height: 75%;
                    margin-left: 2%;
                    background-color: $light-color-100;
                    border: none;
                    border-radius: 1.5vw;
                    align-items: center;
                    color: $dark-color-100;

                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 50%; 
                        font-size: 2.8vw;
                        font-weight: 500;
                    }
    
                    .seller {
                        display: flex;
                        margin-top: 2%;
                        justify-content: center;
                        width: 100%;
                        height: 50%;
                        font-size: 2.6vw;
                        font-weight: 500;
                    }
                }
    
                .price-box {
                    display: flex;
                    flex-direction: column;
                    width: 27%;
                    height: 75%;
                    margin-left: 2%;
                    background-color: $light-color-100;
                    border: none;
                    border-radius: 1.5vw;
                    align-items: center;
                    color: $dark-color-100;

                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 50%; 
                        font-size: 2.8vw;
                        font-weight: 500;
                    }
    
                    .price {
                        display: flex;
                        margin-top: 2%;
                        
                        justify-content: center;
                        width: 100%;
                        height: 50%;
                        font-size: 2.6vw;
                        font-weight: 500;

                        & img {
                            height: 85%;
                            margin-left: 3%;
                        }
                    }
                }

                .buy-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32%;
                    height: 75%;
                    margin-left: 8%;
                    background-color: white;
                    border: none;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 2.8vw;
                    cursor: pointer;

                    & img {
                        height: 75%;
                        margin-right: 3%;
                    }
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: rgb(223, 223, 223);
                        }
                    }
                    &.disabled {
                        opacity: 0.5;
                        cursor: default;
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: white;
                            }
                        }
                    }

                    .loading-animation-buying { 
                        @include load-animation;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        margin-top: 10%;
                        background: none;
                    }

                }
            }

            
        }

        .no-lots-message {
            display: flex;
            margin-top: 3%;
            justify-content: center;
            color: $dark-color-100;
            font-size: 4vw;
        }
    }
}