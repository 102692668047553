
@import '../../../variables';

@mixin store-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    background-color: $light-color-100;
    border-radius: 3vw;
    margin-top: 5%;

    &:first-child {
        margin-top: 1%;
    }
    &:last-child {
        margin-bottom: 5%;
    }

    .store-box-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 7vw;
        color: $dark-color-100;
        font-size: 4vw;
        font-weight: 700;
    }

    .store-box {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: start;
        width: 90%;
        min-height: 15vw;
        background-color: none;
        border-top: solid 2px $dark-color-100;
        margin-top: 1%;
        margin-bottom: 3%;

        .store-cell {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 17%;
            aspect-ratio: 1/1.2;
            margin: 1.5%;
            background-color: white;
            border: solid $dark-color-100 2px;
            box-sizing: border-box;
            border-radius: 2vw;

            .store-cell-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 70%;

                & img {
                    height: 100%;
                }
            }
            
            .store-cell-value {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 30%;
                color: white;
                font-size: 2.5vw;
                font-weight: 500;
                background-color: $blue-color-90;
                border-radius: 0 0 1.35vw 1.35vw;
                border-top: solid $dark-color-100 2px;

                &.full {
                    color: rgb(255, 0, 0);
                    font-weight: 700;
                }
            }
        }
    }
}

.store-header {
    display: flex;
    align-items: center;
    width: 90vw;
    height: 13vw;
    margin-top: 1%;

    .store-back-button { 
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-100;
        border-radius: 1.4vw;
        border: none;
        width: 20%;
        height: 60%;
        cursor: pointer;
        z-index: 15;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        & img {
            height: 90%;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-light;
            }
        }
    }

    .store-title { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;
        height: 100%;
        font-weight: 700;

        .store-title1 {
            color: $white-100;
            font-size: 5vw;
        }

        .store-title2 {
            margin-left: 3%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $light-color-100;
            height: 60%;
            aspect-ratio: 1/1;
            border-radius: 1.5vw;
            color: $dark-color-100;
            font-size: 2.3vw;
        }
    }

    .store-button-upgrade {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue-color-100;
        border-radius: 1.4vw;
        border: none;
        width: 25%;
        height: 65%;
        cursor: pointer;
        z-index: 15;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        color: $white-100;
        font-size: 3vw;
        font-weight: 700;

        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue;
            } 
        }
    }

    // Фейерверк
    $particles: 60;
    $width: 500;
    $height: 400;
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
                random($width)-$width / 2 + px
                random($height)-$height / 1.2 + px
                hsl(random(360), 100, 50);
    $box-shadow2: $box-shadow2, 0 0 #fff
    }
    @mixin keyframes ($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @-ms-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @mixin animation-delay ($settings) {
        -moz-animation-delay: $settings;
        -webkit-animation-delay: $settings;
        -o-animation-delay: $settings;
        -ms-animation-delay: $settings;
        animation-delay: $settings;
    }

    @mixin animation-duration ($settings) {
        -moz-animation-duration: $settings;
        -webkit-animation-duration: $settings;
        -o-animation-duration: $settings;
        -ms-animation-duration: $settings;
        animation-duration: $settings;
    }

    @mixin animation ($settings) {
        -moz-animation: $settings;
        -webkit-animation: $settings;
        -o-animation: $settings;
        -ms-animation: $settings;
        animation: $settings;
    }

    @mixin transform ($settings) {
        transform: $settings;
        -moz-transform: $settings;
        -webkit-transform: $settings;
        -o-transform: $settings;
        -ms-transform: $settings;
    }

    .pyro > .before, .pyro > .after {
        left: 15%;
        top: 3%;
        z-index: 100;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        box-shadow: $box-shadow2;
        @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
    }
            
    .pyro > .after {
        @include animation-delay((1.25s, 1.25s, 1.25s));
        @include animation-duration((1.25s, 1.25s, 6.25s));
    }
                
    @include keyframes(bang) {
        to {
            box-shadow:$box-shadow;
        }
    }
            
        @include keyframes(gravity)  {
        to {
            @include transform(translateY(200px));
            opacity: 0;
        }
    }
            
    @include keyframes(position) {
        0%, 19.9% {
            margin-top: 10%;
            margin-left: 40%;
        }
        20%, 39.9% {
            margin-top: 40%;
            margin-left: 30%;
        }
        40%, 59.9% {  
            margin-top: 20%;
            margin-left: 70%
        }
        60%, 79.9% {  
            margin-top: 30%;
            margin-left: 20%;
        }
        80%, 99.9% {  
            margin-top: 30%;
            margin-left: 80%;
        }
    }
}

.store-boxes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 15%;
    overflow-y: auto;

    .store-resources-box {
        @include store-box();  
    }
    
    .store-components-box {
        @include store-box();
    }
    
    .store-technology-box {
        @include store-box();
    }
}

