@import '../../variables';

@mixin element {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-color-100;
    margin: 0 2%;
    border: none;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    border-radius: 2vw;
    color: $dark-color-100;
    font-size: 3.5vw;
    height: 80%;
}

.modal-list-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;

    .modal-list-box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 100vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .modal-list-header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 10%;
            margin-bottom: 3%;
    
            .back-button {
                position: relative;
                display: flex;
                background-color: $blue-color-100;
                border-radius: 1.4vw;
                border: none;
                align-items: center;
                justify-content: center;
                width: 15%;
                height: 75%;
                cursor: pointer;
                z-index: 15;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                margin-left: 3%;
                margin-right: 3%;
                
                &:active {
                    transform: scale(0.95);
                }
    
                & img {
                    height: 85%;
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }

            .modal-list-title {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 58%;
                color: $dark-color-100;
                font-size: 4vw;
                font-weight: 700;
            }
        }

        .modal-list-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 90%;
            overflow-y: auto;

            .modal-list-voyage-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 90%;
                height: 16%;
                background-color: $blue-color-100;
                cursor: pointer;
                margin-bottom: 3%;
                border: none;
                border-radius: 2vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
    
                .modal-list-voyage-ship {
                    @include element; 
                    width: 30%;
                }
    
                .modal-list-voyage-conts {
                    @include element; 
                    width: 30%;
    
                    &.cont-count-1 {              
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 80%;
                            height: 80%;
        
                            & img {
                                height: 90%;
                                border-radius: 1.5vw;
                            }
        
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 3vw;
                                background-color: white;
                                border-radius: 0 1.5vw 1.5vw 0;
                                width: 50%;
                                height: 65%
                            }
                        }
                    }
    
                    &.cont-count-2 {              
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 48%;
                            height: 80%;
        
                            & img {
                                height: 70%;
                                border-radius: 1.5vw;
                            }
        
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 2.5vw;
                                background-color: white;
                                border-radius: 0 1.2vw 1.2vw 0;
                                width: 40%;
                                height: 50%
                            }
                        }
                    }
    
                    &.cont-count-3 {            
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 33%;
                            height: 80%;
        
                            & img {
                                height: 45%;
                                border-radius: 1vw;
                            }
        
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 2.2vw;
                                background-color: white;
                                border-radius: 0 0.8vw 0.8vw 0;
                                width: 45%;
                                height: 33%
                            }
                        }
                    }
    
                    &.cont-count-4 {
                        display: flex;
                        flex-wrap: wrap;
    
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 30%;
                            height: 40%;
                            margin-top: 1%;
                            margin: 1% 1%;
                
                            & img {
                                height: 90%;
                                border-radius: 1vw;
                            }
                
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 2.2vw;
                                background-color: white;
                                border-radius: 0 0.8vw 0.8vw 0;
                                width: 50%;
                                height: 65%;
                            }
                        }
                    }
    
                    &.cont-count-5 {
                        display: flex;
                        flex-wrap: wrap;
    
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 30%;
                            height: 40%;
                            margin-top: 1%;
                            margin: 1% 1%;
                
                            & img {
                                height: 90%;
                                border-radius: 1vw;
                            }
                
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 2.2vw;
                                background-color: white;
                                border-radius: 0 0.8vw 0.8vw 0;
                                width: 50%;
                                height: 65%;
                            }
                        }
                    }
    
                    &.cont-count-6 {
                        display: flex;
                        flex-wrap: wrap;
    
                        .modal-list-voyage-cont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            width: 30%;
                            height: 40%;
                            margin-top: 1%;
                            margin: 1% 1%;
                
                            & img {
                                height: 90%;
                                border-radius: 1vw;
                            }
                
                            .modal-list-voyage-cont-amount {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $dark-color-100;
                                font-size: 2.2vw;
                                background-color: white;
                                border-radius: 0 0.8vw 0.8vw 0;
                                width: 50%;
                                height: 65%;
                            }
                        }
                    }
                }
    
                .modal-list-voyage-timer {
                    @include element; 
                    width: 30%;
                }
            }
        }

    }
}