@import '../../../variables';

.temp2-box {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 85%;
    border: none;
    background-color: white;
    border-radius: 3vw;
    margin-top: 3%;
    margin-bottom: 5%;
    align-items: center; 
    color: $dark-color-100;

    .temp2-title {
        font-size: 5vw;
        font-weight: 700;
        margin-top: 2%;
    }

    .temp2-img {
        width: 95%;
        border-radius: 3vw;
        margin-top: 2%;
    }

    .temp2-info1 {
        margin-top: 2%;
        font-size: 3.5vw;
        font-weight: 700;
    }
    .temp2-info2 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 95%;
        margin-top: 2%;
        font-size: 3.5vw;
        font-weight: 700;
    }
    .temp2-info3 {
        display: flex;
        align-items: center;
        text-align: justify;
        width: 95%;
        margin-top: 4%;
        font-size: 3vw;
        font-weight: 500;
    }
    .temp2-info4 {
        display: flex;
        align-items: center;
        text-align: justify;
        width: 95%;
        margin-top: 2%;
        font-size: 3vw;
        font-weight: 500;
    }
    .temp2-info5 {
        display: flex;
        align-items: center;
        text-align: justify;
        width: 95%;
        margin-top: 2%;
        font-size: 3vw;
        font-weight: 500;
    }
    .temp2-info6 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        margin-top: 5%;
        font-size: 3.5vw;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 3%;

        .temp2-info7 {
            display: flex;
            align-items: center;

            & img {
                height: 9vw;
                margin-left: 5%;
            }
        }
    }

    .temp2-soon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3vw;
        font-weight: 700;
        background-color: white;
        border-radius: 2vw;
        width: 22%;
        height: 5%;
        margin-top: 40%;
        margin-left: 60%;
    }
}