@import '../../../variables';

.event-info-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 106;

    .box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 120vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 10%;
        align-items: center;

        .event-image {
            width: 90%;
            margin-top: 5%;
            border-radius: 2.5vw;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
        }

        .budget {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 10vw;
            margin-top: 22.5%;
            margin-right: 48%;

            .image-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                aspect-ratio: 1/1;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 1.5vw;

                & img {
                    height: 85%;
                }
            }

            .amount-box {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: $light-color-100;
                width: 75%;
                height: 75%;
                border-radius: 0 1.5vw 1.5vw 0;
                color: $dark-color-100;
                font-size: 2.4vw;
            }
        }

        .partner-collection {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12%;
            aspect-ratio: 1/1;
            padding: 0;
            background: none;
            border: none;
            margin-top: 22%;
            margin-left: 69%;
            cursor: pointer;

            & img {
                width: 100%;
            }
        }

        .text {
            text-align: justify;
            width: 90%;
            color: $dark-color-100;
            font-size: 3.5vw;
            margin-top: 3%;

            &.center {
                text-align: center;
            }
        }

        .box-button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 1.5vw;
            padding: 0;
            width: 40%;
            height: 8%;
            margin-top: 3%;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
            background-color: $blue-color-100;
            color: white;
            font-size: 4vw;

            &:active {
                transform: scale(0.95);
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-blue;
                }
            }
            &.disabled {
                pointer-events: none;
                opacity: 0.7;
            }

            .loading-animation {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%; 
                height: 90%; 

                @include load-animation;
            }
        }
    }
}