
@import '../variables';

.server-error-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: $dark-color-100;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000000;

    .box {
        display: flex;
        flex-direction: column;
        width: 80vw;
        height: 80vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        justify-content: center;
        align-items: center;

        img {
            height: 40%;
        }

        span {
            color: $dark-color-100;
            font-size: 5.5vw;
            text-align: center;
            margin-top: 5%;
            line-height: 1.4;
        }
    }
}
