
@import '../../variables';
@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&display=swap');

.airdrop-page {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .airdrop-header {
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
            background: linear-gradient(to right, #ff5900, #ffe100, #ffee6b); /* Градиент */
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
            font-size: 10vw;
            font-weight: 700;
        }

        .airdrop-info {
            margin-left: 5%;
            height: 50%;
            aspect-ratio: 1/1;
            border: none;
            background-color: #E79A28;
            border-radius: 1vw;
            color: $dark-color-100;
            font-size: 5vw;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #af741a;
                }
            }
        }
    }

    .balance-box {
        margin-top: 5%;
        width: 80vw;
        height: 35vw;
        background-color: $light-color-100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .balance {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 25%;
            color: $dark-color-100;
            font-size: 6vw;

            .coin {
                margin-left: 2.5%;
                font-family: ADLam Display;
                font-weight: 700;
            }
        }

        .balance-box-amount {
            width: 100%;
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                height: 85%;
                margin-right: 3%;
            }

            & span {
                color: $dark-color-100;
                font-size: 8vw;
            }
        }
    }

    .airdrop-wallet {
        width: 70vw;
        height: 16vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
        background-color: $blue-color-100;
        border: none;
        padding: 0;
        border-radius: 3vw;
        color: white;
        font-size: 3.5vw;
        cursor: pointer;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        &:active {
            transform: scale(0.95);
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $hover-blue; 
            }
        }

        & img {
            height: 65%;
            margin-right: 5%;
        }
    }

    .items-box {
        width: 90vw;
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 7%;
        background-color: $light-color-100;
        border-radius: 3vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

        .items-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 12vw;

            .items-title {
                color: $dark-color-100;
                font-size: 5vw;
            }

            .swap-button {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 25%;
                height: 7vw;
                background-color: $blue-color-100;
                padding: 0;
                border-radius: 2vw;
                border: none;
                color: white;
                margin-left: 70%;
                font-size: 2.6vw;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue; 
                    }
                }

                .button-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    & span {
                        text-align: center;
                        width: 70%;
                    }
                    & img {
                        width: 18%;
                    }
                }

                &.swap {
                    margin-top: 23.5%;
                    height: auto;
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $blue-color-100; 
                        }
                    }

                    .button-content {
                        background-color: $dark-color-100;
                        height: 7vw;
                        border-radius: 2vw 2vw 0 0;
                    }

                    .options-box {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 21vw;

                        & button {
                            border: none;
                            background: none;
                            padding: 0;
                            color: white;
                            cursor: pointer;
                            width: 100%;
                            height: 35%;
                            font-size: 2.8vw;

                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    background-color: $hover-blue; 
                                }
                            }
                        }
                    }
                    
                }
            }
        }

        .store {
            display: flex;
            align-content: flex-start;
            justify-content: start;
            flex-wrap: wrap;
            width: 99%;
            min-height: 6vw;
            background-color: $light-color-100;
            border-radius: 0 0 2vw 2vw;
            padding-bottom: 1%;
            padding-left: 1%;

            .element-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
                background-color: white;
                border: solid $dark-color-100 2px;
                height: 17vw;
                aspect-ratio: 1/1.2;
                cursor: pointer;
                margin: 2%;
                border-radius: 1.5vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                & img {
                    height: 75%;
                }

                & span {
                    width: 100%;
                    height: 25%;
                    color: white;
                    background-color: $blue-color-100;
                    border-radius: 0 0 0.8vw 0.8vw;
                    box-sizing: content-box;
                    font-size: 3vw;
                    border-top: solid $dark-color-100 2px;
                    box-sizing: border-box;
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgb(223, 223, 223);
                    }
                }
            }

            .no-items {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                color: $dark-color-100;
                font-size: 4vw;
                margin: 5% 0;
            }
        }
    }
}