
@import '../../../variables';

.rent-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow-y: auto;

    .rent-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 12vw;
    
        .rent-back-button { 
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white-100;
            border-radius: 1.4vw;
            border: none;
            width: 20%;
            height: 65%;
            left: 5vw;
            cursor: pointer;
            z-index: 15;
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
            &:active {
                transform: scale(0.95);
            }
            & img {
                height: 90%;
            }
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $hover-light;
                }
            }
        }
    
        .rent-title { 
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: 100%;
            color: $white-100;
            font-size: 4.5vw;
            font-weight: 700;
            margin-right: 15%;
        }
    }
    
    .rent-slider-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-color-100;
        width: 90vw;
        margin-top: 1vw;
        border-radius: 2vw;
    
        .rent-slider {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40vw;
    
            .rent-ship-item {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 90%;
                border: none;
                background: none;
                border-radius: 2vw;
                cursor: pointer;
    
                &:nth-child(1) {
                    margin-left: -50%;
                    z-index: 11;
                }
                &:nth-child(2) {
                    z-index: 12;
                }
                &:nth-child(3) {
                    margin-left: 50%;
                    z-index: 11;
                }      
    
                & img {
                    height: 100%;
                    border-radius: 2vw;
                    border: solid $dark-color-100 2px;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                }
    
                .rent-ship-slider-name {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 7%;
                    top: 4%;
                    background-color: rgba(255,255,255,0.8);
                    width: 50%;
                    height: 15%;
                    border-radius: 1.5vw;
                    color: $dark-color-100;
                    font-size: 3vw;
                    font-weight: 700;
                }
            }
        }
    
    }
    
    .rent-info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $light-color-100;
        width: 90vw;
        min-height: 17vw;
        margin-top: 4%;
        border-radius: 2vw;
        color: $dark-color-100;
        font-size: 3.5vw;
        & span {
            margin-bottom: 0.5%;
        }
    }
    
    .rent-lots-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $light-color-100;
        width: 90vw;
        margin-top: 4%;
        border-radius: 3vw;
        margin-bottom: 5vw;
    
        .rent-no-lots {
            width: 80%;
            height: 20vw;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $dark-color-100;
            font-size: 5vw;
        }
    
        .rent-lot-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            min-height: 18vw;
            padding-top: 4%;
    
            .rent-lot {
                position: relative;
                display: flex;
                align-items: center;
                background-color: $blue-color-100;
                width: 94%;
                height: 15vw;
                margin-bottom: 4%;
                border-radius: 3vw;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                .rent-lot-owner {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-color-100;
                    border-radius: 2vw;
                    width: 27%;
                    height: 75%;
                    margin-left: 4%;
                    color: $dark-color-100;
    
                    .rent-lot-owner-title {
                        font-size: 2.8vw;
                        font-weight: 500;
                        margin-bottom: 4%;
                    }
    
                    .rent-lot-owner-wallet {
                        font-size: 2.8vw;
                        font-weight: 700;
                    }
                }
    
                .rent-lot-lvl {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-color-100;
                    border-radius: 2vw;
                    width: 16%;
                    height: 70%;
                    margin-left: 3%;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                    font-weight: 700;
                }
    
                .rent-lot-capacity {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-color-100;
                    border-radius: 2vw;
                    width: 16%;
                    height: 70%;
                    margin-left: 3%;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                    font-weight: 700;
    
                    & img {
                        height: 60%;
                        margin-left: 3%;
                    }
                }
    
                .rent-lot-buy {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    border-radius: 2vw;
                    cursor: pointer;
                    right: 3%;
                    height: 55%;
                    width: 25%;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
    
                    & img {
                        height: 60%;
                        margin-right: 6%;
                    }
    
                    &:active {
                        transform: scale(0.95);
                    }
    
                    &.disabled {
                        opacity: 1;
                        pointer-events: none;
                    }
    
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $hover-light;
                        }
                    }
    
                    .rent-lot-buy-price {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
    
                        & span {
                            color: $dark-color-100;
                            font-size: 2.5vw;
                            margin-bottom: 3%;
                            &:nth-child(1) {
                                font-weight: 500;
                            }
                            &:nth-child(2) {
                                font-weight: 700;
                            }
                        } 
                    }
    
                    .loading-animation-renting {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%; 
                        height: 90%; 
    
                        @include load-animation;
                    }   
                }
            }
        }
    
        .rent-loading-animation {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70%; 
    
            @include load-animation;
        }
        
    }
}

.rent-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    z-index: 10;

    .rent {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        border-radius: 3vw;
        background-color: white;
        width: 85vw;
        height: 85vw;

        .rent-message {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            height: 75%;
            margin-top: 3%;
      
            .rent-ship {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 87%;

                & img {
                    height: 100%;
                    border-radius: 3vw;
                }

                .rent-ship-name {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 15%;
                    width: 55%;
                    background-color: $white-90;
                    top: 5%;
                    left: 5%;
                    border-radius: 1vw;
                    color: $dark-color-100;
                    font-size: 2.5vw;
                    font-weight: 700;
                }

                .rent-ship-lvl {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 15%;
                    width: 28%;
                    background-color: $white-90;
                    top: 5%;
                    right: 5%;
                    border-radius: 1vw;
                    color: $dark-color-100;
                    font-size: 2.5vw;
                    font-weight: 700;
                }

                .rent-ship-capacity {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 15%;
                    width: 28%;
                    background-color: $white-90;
                    bottom: 5%;
                    right: 5%;
                    border-radius: 1vw;
                    color: $dark-color-100;
                    font-size: 3vw;
                    font-weight: 700;

                & img {
                    height: 80%;
                    margin-left: 3%;
                }
                }
            }

            .rent-message-answer {
                display: flex;
                color: $dark-color-100;
                font-size: 3.5vw;
                font-weight: 900;
                margin-top: 3%;
                width: 95%;
                text-align: center;
            }
        }

        .rent-buttons {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            min-height: 17%;
            margin-top: 20%;

            .rent-button-yes {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.8vw;
                font-weight: 700;
                width: 35%;
                height: 100%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;

                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.disabled {
                    opacity: 1;
                    pointer-events: none;
                }

                .loading-animation {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 80%; 
            
                    @include load-animation;
                }
            }

            .rent-button-no {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 3.8vw;
                font-weight: 700;
                width: 35%;
                height: 100%;
                margin-left: 20%;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                cursor: pointer;

                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }

                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }       
    }

    .rent-views {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 3vw;
        background-color: white;
        width: 80%;
        height: 24%;

        .rent-message-views-true {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            white-space: nowrap;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 900;
            text-align: center;
            align-items: center;
            width: 100%;
            height: 70%;

            & span {
                margin-bottom: 3%;
            }

            .rent-button-ok {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 2vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 4vw;
                font-weight: 900;
                width: 35%;
                min-height: 33%;
                margin-top: 5%;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);

                &:active {
                    transform: scale(0.95);
                }
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
            
        }
    }
}