
@import '../variables';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.load {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('./fon.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .logo {
        width: 90%;
        margin-top: 20%;
    }

    .loading-animation-load {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%; 
        height: 90%; 
        margin-top: 20%;

        @include load-animation;

    }

    .loading-progress-bar-v2 {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        height: 0.8%;
        bottom: 0;

        .loading-progress-bar-fill {
            height: 100%;
            background-color: rgb(216, 176, 0);
            transition: width 0.3s ease-in-out;
        }

        .loading-progress {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.5vw;
            color: rgb(216, 176, 0);
            font-weight: 700;
            margin-bottom: 5.5%;
            margin-left: 1%;
        }
    }

    .version {
        position: absolute;
        bottom: 5%;
        color: white;
        font-size: 5vw;
    }
}

.preloading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    height: 100%;
    overflow: hidden;
    text-wrap: wrap;
    color: white;
    font-size: 12vw;
    font-weight: 700;
}