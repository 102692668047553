
@import '../../variables';

.voyage-full-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .box {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 38vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        align-items: center;

        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52vw;
            height: 13vw;
            background-color: $orange;
            border-radius: 3vw;
            margin-top: -6.5vw;
            color: $dark-color-100;
            font-size: 4vw;
            font-weight: 700;
        } 

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 90%;
            margin-top: 10%;
            font-size: 3.5vw;
            color: $dark-color-100;
            font-weight: 500;

            & span {
                margin-bottom: 4%;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            height: 30%;

            .button1 {
                color: white;
                background-color: $blue-color-100;
                border: none;
                border-radius: 2vw;
                height: 80%;
                width: 27%;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                font-size: 3.5vw;
                padding: 0;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }

            .button2 {
                color: white;
                background-color: $blue-color-100;
                border: none;
                border-radius: 2vw;
                height: 80%;
                width: 50%;
                cursor: pointer;
                box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.30);
                font-size: 3vw;
                padding: 0;

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: $hover-blue;
                    }
                }
            }
        }

    }
}
