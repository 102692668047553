@import '../../variables';

.rating-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.82);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 11;

    .rating-box {
        display: flex;
        flex-direction: column;
        width: 90vw;
        height: 100vw;
        border: none;
        background-color: white;
        border-radius: 3vw;
        margin-top: 25%;
        align-items: center; 

        .rating-header {
            width: 100%;
            height: 9%;
            display: flex;
            align-items: center;
            justify-content: center;

            .rating-title {
                color: $dark-color-100;
                font-size: 5vw;
                font-weight: 900;
            }
        }

        .your-rating-box {
            width: 70%;
            height: 25%;
            display: flex;
            flex-direction: column;
            align-items: center; 
            margin-top: 1%;
            border-radius: 3vw;
            background-color: $light-color-100;

            .your-rating {
                color: $dark-color-100;
                font-size: 4.5vw;
                font-weight: 900;
                margin-top: 4%;
            }

            .your-rating-your {
                width: 100%;
                height: 60%;
                display: flex;
                align-items: center;
                justify-content: center;

                .your-rating-number {
                    color: $dark-color-100;
                    font-size: 10vw;
                    font-weight: 900;
                }

                & img {
                    height: 70%;
                    margin-left: 1%;
                }
            }  
        }

        .all-rating {
            width: 90%;
            height: 55%;
            display: flex;
            flex-direction: column;
            align-items: center; 
            margin-top: 5%;
            border-radius: 3vw;
            background-color: $light-color-100;
            overflow-y: auto;
            padding-bottom: 3%;

            .rating-user {
                display: flex;
                align-items: center;
                width: 95%;
                height: 25%;
                margin-top: 3%;

                .rating-user-number {
                    display: flex;
                    align-items: center; 
                    justify-content: center;
                    color: $dark-color-100;
                    font-size: 3.5vw;
                    margin-right: 3%;
                    width: 10%;
                    &.current-user {
                        height: 35%;
                        border-radius: 1.5vw;
                        background-color: $blue-color-100;
                        color: white;
                    }
                }

                .rating-user-img {
                    display: flex;
                    align-items: center; 
                    justify-content: center;
                    height: 100%;
                    aspect-ratio: 1/1;
                    background-color: $dark-color-100;
                    border-radius: 2vw;
                    & img {
                        height: 100%;
                    }
                }

                .rating-user-box {
                    display: flex;
                    align-items: center;
                    height: 80%;
                    width: 68%;
                    border-radius: 0 2vw 2vw 0;
                    background-color: $blue-color-100;

                    .rating-user-name {
                        display: flex;
                        flex-direction: column;
                        align-items: center; 
                        justify-content: center;
                        text-wrap: nowrap;
                        height: 80%;
                        width: 40%;
                        margin-left: 3%;
                        border-radius: 1.5vw;
                        background-color: $light-color-100;
                        overflow: hidden;
                        color: $dark-color-100;
                        font-size: 2.5vw;
                    }

                    .rating-user-lvl {
                        display: flex;
                        flex-direction: column;
                        align-items: center; 
                        justify-content: center;
                        height: 80%;
                        width: 20%;
                        margin-left: 34%;
                        border-radius: 1.5vw;
                        background-color: $light-color-100;
                        color: $dark-color-100;

                        & span:first-of-type {
                            font-size: 2vw;
                        }
                        & span:last-of-type {
                            font-size: 3.5vw;
                        }
                    }
                }
            }

            .dots {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3%;
                width: 20%;
                min-height: 8%;
                border-radius: 1.5vw;
                background-color: $blue-color-100;
                color: white;
                font-size: 5vw;
            }
        }

        .rating-animation{ 
            position: relative;
            display: flex;
            flex-direction: column;
            height: 70%;
            //width: 90vw;
            margin-top: 2%;
            align-items: center;
            justify-content: center;
            border: none;
            //background-color: $light-color-90; 
        
            @include load-animation;
        }
    } 
}